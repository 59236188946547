@import '../../../Main.module.scss';

.tree_view {
    &_highlight_text {
        color: yellow;
    }

    &_item_icon {
        cursor: pointer;
        height: 14px !important;
    }

    &_item_selected {
        cursor: pointer;
        color: $primary-text-color;
        padding: 5px 0px 5px;
        background-color: $ag-row-hover;
        text-decoration-style: none;
        &::selection{
            outline: none;
            color: $primary-text-color;
            background: transparent;
        }
    }

    &_item {
        cursor: pointer;
        background-color: transparent;
        color: $primary-text-color;
        padding: 5px 0px 5px;
        &_disabled {
            padding-left: 25px;
            cursor: not-allowed;
            color: lightgrey;
        }
        &::selection{
            outline: none;
            color: $primary-text-color;
            background-color: transparent;
        }
    }
}
