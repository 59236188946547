@import '../../../Main.module.scss';

.production {
    &_main_container,
    &_margin_container {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 20px;
        height: 650px;
        margin-bottom: 30px;
    }

    &_margin_container {
        height: 450px;
    }

    &_projection_chart, &_seasonal_chart {
        width: 66%;
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    &_commentary_container {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        height: 100%;
        width: 34%;
    }

    &_charts_container {
        display: flex;
        width: 100%;
        gap: 20px;
    }

    &_projection_chart_50, &_seasonal_chart_50 {
        flex: 1 0.5 50%;
        display: flex;
        flex-direction: column;
        height: 450px;
        width: 50%;
        max-width: 850px;
    }
}
