@import '../../../../Main.module.scss';

.products_modal {
    &_content {
        width: 670px;
    }

    &_header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    
    &_sub_header {
        font-size: 14px;
        font-weight: 500;
    }
  
    &_tabs {
        &_container {
            margin-bottom: 20px;
            display: flex;
            width: 100%;
        }

        &_tab {
            @include hover-cursor-pointer;
            @include justify-and-align-flex-items-center;
            font-size: 14px;
            font-weight: bold;
            background: none;
            border: none;
            padding: 12px 16px;
            min-width: 54px;

            &:first-child {
                margin-left: 0px;
            }

            &_selected {
                font-size: 14px;
                font-weight: bold;
                background: none;
                border: none;
                padding: 12px 16px;
                min-width: 54px;
                border: 1px solid $stonex-light-gray-blue;
                border-bottom: none;
                border-radius: 3px 3px 0 0;
            }
        }

        &_empty_underline_space {
            flex: 1;
        }

        &_tab,
        &_empty_underline_space {
            color: $primary-text-color;
            border-bottom: 1px solid $stonex-light-gray-blue;
        }
    }
    

    &_body{
        width: 100%;
        height: 488px;
    }

    &_manage_input_row{
        display: flex;
        margin-bottom: 20px;
        gap: 15px;
    }

    &_manage_input_text{
        width: 100%;
        max-width: 500px;
    }

    &_tree_selection_row{
        display: flex;
        flex:1;
        gap: 15px;
    }

    &_product_column{
        width: 50%;
    }
    &_left_right_buttons {
        margin: auto;
    }
    &_movement_button {
        @include hover-cursor-pointer;
        background-color: $plainWhite;
        color:  $stonex-medium-gray;
        width: 48px;
        height: 48px;
        border-radius: 3px;
        border: 1px solid $stonex-disabled-light-gray;
        margin: auto;
        display: block;
        margin: 0 0 12px 0;
    }
    &_arrow_icons {
        font-size: 35px !important; 
        height: 45px !important;
    }
    &_product_selection_column_subheader{
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &_product_column_subheader_text{
        font-size: 14px;
        font-weight: 700;
    }

    &_tree_container{
        margin-top: 10px;
        height: 400px;
        overflow: auto;
        border: 1px solid $stonex-light-gray-blue;
        border-radius: 3px;
    }

    &_selector_actions{
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 15px 15px 15px;
    }

    &_actions_right{
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        text-align: right;
        gap: 15px;
    }

    &_product_list_table {
        height: 400px;
        overflow: auto;

    }

    &_product_list_table_row {
        height: 42px;
        line-height: 15px;
        display: flex;
        flex: 1;
        border-left: 1px solid $stonex-gray-blue-border;
        cursor: pointer;
        &_selected {
            background: $ag-row-hover;
            height: 35px;
            display: flex;
        }
    }

    &_product_list_table_header_cell {
        height: 100%;
        width: 100%;
        padding-left: 10px;
        display: flex;
        align-items: center;
        max-width: 300px;
        background-color: $stonex-off-white;
        font-size: 14px;
        font-weight: 700;
        line-height: 17.57px;
        border-top: 1px solid $stonex-light-gray-blue;
        border-bottom: 1px solid $stonex-light-gray-blue;
        border-right: 1px solid $stonex-light-gray-blue;
        &:last-child {
            max-width: 50px;
            min-width: 50px;
            text-align: center;
        }
    }

    &_product_list_table_cell {
        height: 100%;
        width: 100%;
        padding-left: 10px;
        max-width: 300px;
        display: flex;
        align-items: center;
        border-bottom: 1px solid $stonex-light-gray-blue;
        border-right: 1px solid $stonex-light-gray-blue;
        &:last-child {
            max-width: 50px;
            min-width: 50px;
            padding-left: 0px;
            justify-content: center;
            text-align: center;
        }
    }
}

.menu_setting{
    &_container{
        min-height: 584px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 32px;
    }

    &_section{
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    &_section_header{
        font-family: $application-font-family;
        font-weight: 600;
        font-size: 16px;
        line-height: 20.08px;
        letter-spacing: 0%;
    }

    &_section_text{
        font-family: $application-font-family;
        font-weight: 400;
        font-size: 14px;
        line-height: 17.57px;
        letter-spacing: 0%;
    }

    &_tree_container{
        overflow: auto;
        max-height: 300px;
        min-height: 300px;
        border: 1px solid $stonex-disabled-light-gray;
        border-radius: 3px;
    }

    &_radio{
        display: flex;
        gap: 10px;
    }
}