@import '../../../../Config.module.scss';

.badge {
    display: inline-flex;
    align-items: center;
    padding: 4px;
    width: auto;
    max-width: fit-content;

    &_icon {
        &_approved {
            color: $stonex-positive-green;
        }

        &_rejected {
            color: $stonex-negative-red;
        }

        &_expireSoon {
            color: $very-bearish-color;
        }

        &_expired {
            color: $stonex-negative-red;
        }
    }

    &_text {
        font-size: 12px;
        font-weight: 500;
        color: inherit;
        margin-left: 8px;

        &.no-icon {
            margin-left: 0;
        }
    }
}
