.toggleable_container {
    &_text_button {
        background: none;
        border: none;
        cursor: pointer;
        display: flex;
        align-items: center;
        font-size: 14px;
        text-decoration: none;
        padding: 0;
    }

    &_content {
        margin-bottom: 15px;
    }
}
