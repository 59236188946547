@import "../../../Main.module.scss";

.calculators {
    &_title_container {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &_page_title {
        @include stonex-bold-font;
        line-height: 40px;
        font-size: 28px;
        margin-bottom: 24px;
    }

    &_tab {
        display: flex;
        flex-direction: row;
    }

    &_tab_item {
        border: none;
        background-color: transparent;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 10px 20px 9px;
        gap: 20px;
    }

    &_tab_item_selected {
        border: none;
        background-color: transparent;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 10px 20px 9px;
        gap: 20px;
        border-bottom: 2px solid $stonex-action-blue;
    }
}