@import '../../../Main.module.scss';

.dashboard {
    &_gray_background {
        background-color: $stonex-off-white;
        max-width: 100%;
        padding: 20px 0;
    }

    &_outlook_indicators,
    &_guage_charts {
        margin: 12px;
        display: flex;
        flex-flow: wrap;
        flex: 1;

        button {
            margin-top: 8px;
        }
    }

    &_guage_chart,
    &_outlook_indicator {
        margin: 8px;
    }

    &_news_item {
        display: flex;
        border-radius: 3px;
        background-color: $plainWhite;
        margin: 6px 0;
    }

    &_header {
        padding: 0 16px;
        margin-bottom: 0;
        justify-items: flex-start;
    }

    &_market_intelligence_content {
        padding: 0 16px;
        height: 270px;
        overflow-y: scroll;
    }

    &_image {
        width: 88px;
        height: 88px;
        margin-right: 16px;
    }

    &_published_date {
        font-size: 12px;
        margin-bottom: 16px;
        color: $stonex-medium-gray;
    }

    &_title {
        @include hover-cursor-pointer;
        font-size: 16px;
        margin-top: 16px;
        font-weight: 700;
        line-height: 20px;
        padding-left: 0;
        background-color: transparent;
        border: none;
        color: $stonex-primary-blue;
    }

    &_author {
        font-size: 12px;
        font-weight: 600;
    }
}

.commodity_summary_table {
    &_title_line {
        position: relative;

        & div:first-child {
            position: absolute;
        }
    }

    &_no_wrap_flex {
        display: flex;
        flex-wrap: nowrap;
    }

    &_top_title {
        background-color: $stonex-off-white;
        border: 1px solid $stonex-light-gray-blue;
        border-bottom: 0px;
        margin-left: 605.5px;
        height: 55px;
        display: flex;
        justify-content: center;
        align-items: center;

        &_w3 {
            width: 488.5px;
        }

        &_w4 {
            width: 610.5px;
        }
    }

    &_title {
        font-weight: 700;
        font-size: 18px;
    }

    &_row {
        display: flex;
    }

    &_header_item,
    &_header_item_extra_large {
        @include stonex-bold-font;
        height: 48px;
        text-align: center;
        background-color: $stonex-off-white;
        border: 1px solid $stonex-gray-blue-border;
        border-left: none;
        padding-top: 15px;
        font-weight: 700;
    }

    &_header_item {
        flex: 0 0 122px;
    }

    &_header_item_extra_large {
        flex: 0 0 240px;
    }

    &_cell,
    &_cell_split_value_large,
    &_cell_split_value_small,
    &_cell_extra_large,
    &_median {
        font-size: 14px;
        height: 48px;
        line-height: 48px;
        border: 1px solid $stonex-gray-blue-border;
        border-left: none;
        border-top: none;
        display: inline-grid;
        margin-bottom: 0;
    }

    &_median {
        @include hover-cursor-pointer;
        & a span {
            color: white;
        }
    }

    &_sub_text {
        padding: 2px 0 0 5px;
        font-size: 12px;
        color: $stonex-medium-gray;
        font-weight: 400;
    }

    &_cell,
    &_median {
        flex: 0 0 122px;
        width: 122px;
        padding: 0 18px;
        margin: 0;
        padding: 0px 18px;
        align-items: center;
        justify-content: center;
    }

    &_cell_extra_large {
        padding: 0px 18px;
        font-weight: 600;
        flex: 0 0 240px;
        width: 240px;
    }

    &_cell_split_value_large {
        width: 80px;
        padding: 0 8px;
        flex: 0 0 80px;
        justify-content: center;
    }

    &_cell_split_value_small {
        width: 48px;
        padding: 0 5px;
        flex: 0 0 42px;
        justify-content: center;
    }

    &_footnote {
        font-size: 12px;
        color: $stonex-medium-gray;
        font-weight: 600;
        margin-top: 12px;
        line-height: 15px;
    }
}
