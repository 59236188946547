@import "../../../../Main.module.scss";

.logout {
    &_image_button {
        justify-content: right;
    }

    &_image {
        height: 24px;
        width: 24px;
        margin-left: 4px;
        @include hover-cursor-pointer;
        vertical-align: top;
    }

    &_container {
        justify-content: flex-end;
        display: flex;
    }
}