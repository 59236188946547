@import "../../../../Main.module.scss";

.alert_modal {
  &_container {
    @include justify-and-align-flex-items-center;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height: 100vh;
    display: flex;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 10000;
  }

  &_box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    z-index: 1;
    padding: 15px;
    width: 30%;
    background: white;
    display: flex;
    border-radius: 3px;
    position: relative;
    animation: animate 0.3s;
    font-family: 'Mulish';
    gap: 15px;
  }
  
  &_modal_header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    h1 {
      font-size: 18px;
      font-weight: 700;
      margin-top: 0px;
      margin-bottom: 0px;
    }

    button {
      border: none;
      background-color: transparent;
      cursor: pointer;
      font-size: 15px;
      color: #77787A;
    }
  }

  &_message_container {
    display: flex;
    align-items: center;
    width: 100%;

    h2 {
      font-size: 16px;
      font-weight: 600;
    }
  }

  &_action_button_container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 15px;
    width: 100%;

  }

  &_checkbox_container {
    display: flex;
    align-items: center;
    gap: 15px;

    h2 {
        font-size: 16px;
        font-weight: 600;
    }
  }

  &_close {
    @include hover-cursor-pointer;
    color: $stonex-disabled-light-gray;
  }
}

  @keyframes animate {
    from {
      transform: scale(0.5);
    }
    to {
      transform: scale(1);
    }
  }