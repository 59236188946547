
@import "../../../../Main.module.scss";

.calculators {
    &_dropdown_container {
        @include justify-and-align-flex-items-center;
        display: flex;
        flex-direction: row;
    }
    &_fileselector_container {
        width: 100%;
    }
    &_container {
        @include justify-and-align-flex-items-center;
        display: flex;
        flex-direction: column;
    }
    &_sub_heading_bold_item {
        font-weight: 700;
        font-size: 18px;
        line-height: 23px;
    }
    &_sub_heading_item {
        padding-top: 16px;
        font-size: 18px;
        line-height: 23px;
    }
    &_sub_heading_main {
        padding-top: 10px;
    }
    &_calculation_container {
        margin-top: 38px;
        margin-right: 32px;
        padding-left: 32px;
        padding-right: 32px;
        max-width: 1027px;
        min-height: 307px;
        background: $stonex-off-white;
        border: 1px solid $stonex-gray-red-hue;
        box-shadow: 1px 1px 4px $stonex-boxshadow-black;
        border-radius: 3px;
    }
    &_calculation_heading {
        margin-top: 32px;
        font-weight: 700;
        font-size: 16px;
        line-height: 20px;
    }
    &_input_container {
        margin-top: 16px;
        padding-left: 0px !important;
        display: flex;
        flex-direction: row;
        width: 100%;
    }
    &_input {
        width: 167px;
        padding: 0 32px 0 0;
    }
    &_input_box_container {
        padding-left: 0px !important;
        min-width: 500px;
        margin-bottom: 31px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    &_percent_label {
        position: relative;
        bottom: 30px;
        left: 140px;
        width: 20px;
    }
    &_result_container {
        margin-top: 32px;
        padding-top: 16px;
        width: 602px;
        min-height: 440px;
    }
    &_button_cancel {
        @include stonex-bold-font;
        @include hover-cursor-pointer;
        float: right;
        position: relative;
        right: 32px;
        bottom: 64px;
        padding: 6px 16px;
        background-color: white;
        border: 1px solid $stonex-light-gray;
        border-radius: 2px;
        font-size: 14px;
        line-height: 17.57px;
        text-transform: uppercase;
        letter-spacing: 0.07em;
    }
    &_input {
        margin-top: 12px;
    }
    &_result_upper_container {
        display: flex;
        padding-top: 16px;
        flex-direction: column;
        width: 95%;
    }
    &_result_upper_item {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    &_average_price {
        display: block;
        margin: 0 20px;
        flex-direction: row;
    }
    &_result_price {
        display: flex;
        flex-direction: row;
        width: 270px;
        justify-content: space-between;
    }
    &_result_price_heading {
        font-weight: 400;
        font-size: 16px;
        line-height: 40px;
    }
    &_result_price_unit {
        font-weight: 700;
        font-size: 16px;
        line-height: 40px;
    }
    &_average_price_unit {
        font-weight: 700;
        font-size: 16px;
        line-height: 40px;
        margin-top: 5px;
    }
    &_result_table {
        display: flex;
        flex-direction: column;
        width: 95%;
        margin-top: 20px;
    }
    &_result_table_row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        height: 32px;
        align-items: center;
    }
    &_result_table_bar {
        background: $stonex-light-gray-blue;
        width: 573px;
        height: 1px;
    }
}
