@import '../../../../Main.module.scss';

.basis_calculator {
    &_header_row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-width: calc(100vw - 278px);
    }

    &_content_row {
        display: flex;
        align-items: flex-start;
        min-width: 100%;
    }

    &_content_right {
        padding: 25px 0 0 25px;
    }

    &_controls {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: flex-end;
        text-align: right;
        gap: 10px;

        p {
            display: flex;
            align-items: center;
            margin: 0;
        }
    }

    &_form {
        width: 582px;
        flex-shrink: 0;
        background-color: $stonex-off-white;
        border: 1px solid $stonex-gray-red-hue;
        padding: 20px;
        margin: 32px 0;
        box-shadow: 1px 1px 4px $stonex-boxshadow-black;
        border-radius: 3px;

        &_optimal_lag {
            background-color: $active-block-color;
            border-radius: 3px;
            padding: 10px 15px;
            margin-left: 10px;
            text-transform: uppercase;
            white-space: nowrap;
        }

        &_lag_period_header {
            margin-bottom: 3px;
        }
    }

    &_grid {
        flex-grow: 1;
        padding: 20px 20px 0px 0px;
        margin: 12px 0;

        &_interval {
            width: 240px;
            margin-bottom: 20px;
        }

        &_table {
            display: inline-table;
            border-collapse: collapse;

            &_row {
                display: table-row;

                &:nth-child(odd) {
                    background-color: $neutral-color;
                }
            }

            &_cell {
                display: table-cell;
                min-width: 50px;
                text-align: center;
                box-sizing: border-box;
                padding: 15px 4px;
                border: 1px solid $stonex-light-gray-blue;
                white-space: nowrap;
            }

            &_cell_empty {
                @extend .basis_calculator_grid_table_cell;
                background: white;
                border-top: 0;
                border-left: 0;
            }

            &_cell_header {
                @extend .basis_calculator_grid_table_cell;
                font-weight: bold;
                border-left: 0;
                padding: 15px;
            }

            &_cell_average {
                @extend .basis_calculator_grid_table_cell;
                background-color: $deactivated-block-color;
            }

            &_cell_decile10 {
                @extend .basis_calculator_grid_table_cell;
                background-color: $ninety-to-one-hundred-decile;
                color: white;
            }
            &_cell_decile9 {
                @extend .basis_calculator_grid_table_cell;
                background-color: $ninety-to-one-hundred-decile;
                color: white;
            }
            &_cell_decile8 {
                @extend .basis_calculator_grid_table_cell;
                background-color: $seventy-to-eighty-decile;
            }
            &_cell_decile7 {
                @extend .basis_calculator_grid_table_cell;
                background-color: $sixty-to-seventy-decile;
            }
            &_cell_decile6 {
                @extend .basis_calculator_grid_table_cell;
                background-color: $fifty-to-sixty-decile;
            }
            &_cell_decile5 {
                @extend .basis_calculator_grid_table_cell;
                background-color: $fourty-to-fifty-decile;
            }
            &_cell_decile4 {
                @extend .basis_calculator_grid_table_cell;
                background-color: $thirty-to-fourty-decile;
            }
            &_cell_decile3 {
                @extend .basis_calculator_grid_table_cell;
                background-color: $twenty-to-thirty-decile;
            }
            &_cell_decile2 {
                @extend .basis_calculator_grid_table_cell;
                background-color: $ten-to-twenty-decile;
            }
            &_cell_decile1 {
                @extend .basis_calculator_grid_table_cell;
                background-color: $zero-to-ten-decile;
                color: white;
            }
            &_cell_decile0 {
                @extend .basis_calculator_grid_table_cell;
                background-color: $zero-to-ten-decile;
                color: white;
            }
        }
    }

    &_chart {
        height: 435px;

        &_carousel {
            width: 670px;
        }
    }

    &_startup {
        width: 582px;
        flex-shrink: 0;
        background-color: $stonex-off-white;
        border: 1px solid $stonex-gray-red-hue;
        padding: 20px;
        margin: 32px 0;
        box-shadow: 1px 1px 4px $stonex-boxshadow-black;
        border-radius: 3px;

        &_button {
            width: 100%;
            background: white;
            font-weight: bold;
            border: none;
            color: $zero-to-ten-decile;
            cursor: pointer;
            font-size: 14px;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 5px 10px;
            gap: 8px;
            box-shadow: 1px 1px 4px $stonex-boxshadow-black;
            border-radius: 3px;

            &_icon {
                height: 14px;
                width: 14px;

                path {
                    fill: currentColor;
                }
            }
        }
    }

    &_product_selector {
        &_header {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        &_content {
            width: 670px;

            &_tabs {
                &_container {
                    display: flex;
                }

                &_tab {
                    @include hover-cursor-pointer;
                    @include justify-and-align-flex-items-center;
                    font-size: 14px;
                    font-weight: bold;
                    background: none;
                    border: none;
                    padding: 12px 16px;
                    min-width: 54px;

                    &:first-child {
                        margin-left: 0px;
                    }

                    &_selected {
                        font-size: 14px;
                        font-weight: bold;
                        background: none;
                        border: none;
                        padding: 12px 16px;
                        min-width: 54px;
                        border: 1px solid $stonex-light-gray-blue;
                        border-bottom: none;
                        border-radius: 3px 3px 0 0;
                    }
                }

                &_empty_underline_space {
                    flex: 1;
                }

                &_tab,
                &_empty_underline_space {
                    color: $primary-text-color;
                    border-bottom: 1px solid $stonex-light-gray-blue;
                }
            }

            &_section {
                &_header {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 5px;
                }

                &_selection {
                    margin-top: 10px;
                    padding: 15px;
                    height: 400px;
                    overflow: auto;
                    border: 1px solid $stonex-light-gray-blue;
                    border-radius: 3px;
                }

                &_product_list {
                    margin-top: 10px;
                    padding: 15px;
                    height: 488px;
                    overflow: auto;

                    &_table {
                        width: 100%;
                        border-collapse: collapse;
                        display: table;

                        &_row {
                            display: table-row;
                            cursor: pointer;
                        }

                        &_row_selected {
                            @extend .basis_calculator_product_selector_content_section_product_list_table_row;
                            background: $ag-row-hover;
                        }

                        &_cell {
                            display: table-cell;
                            padding: 5px 15px;
                            border: 1px solid $stonex-light-gray-blue;

                            &:last-child {
                                width: 50px;
                                text-align: center;
                            }
                        }

                        &_header_cell {
                            @extend .basis_calculator_product_selector_content_section_product_list_table_cell;
                            background: $stonex-off-white;
                            font-weight: bold;
                        }
                    }
                }
            }
        }

        &_actions {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 15px 15px 15px;
        }

        &_actions_right {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-end;
            text-align: right;
            gap: 15px;
        }
    }

    &_container {
        display: grid;
        gap: 15px;
    }

    &_row {
        display: flex;
        gap: 15px;
    }

    &_cell {
        &_full_width {
            flex: 1 1 100%;
        }

        &_half_width {
            flex: 1 1 calc(50% - 7.5px);
        }

        &_third_width {
            flex: 1 1 calc(33.33% - 5px);
        }
    }

    & hr {
        border: none;
        border-top: 1px solid $stonex-light-gray-blue;
    }

    &_link {
        background: none;
        border: none;
        padding: 0;
        cursor: pointer;
        color: $stonex-action-blue;
    }
}
