@import '../../../../Main.module.scss';

.indicators_frequency {
    &_flex {
        display: flex;
        margin: 0 0 -15px 10px;       
    }

    &_header {
        color: $stonex-medium-gray;
        font-size: 10px;
        font-weight: 700;
        margin-right: 3px;
        
    }

    &_footnote {
        color: $stonex-medium-gray;
        font-size: 10px;            
    }    
}