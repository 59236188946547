@import '../../../../Main.module.scss';

.valorization_calculator {
    &_gray_selection_area {
        display: flex;
        height: 100%;
        width: 100%;
        background-color: $stonex-off-white;
        border: 1px solid $stonex-gray-red-hue;
        padding: 20px;
        margin: 32px 0 20px;
        border-radius: 3px;
        min-width: 1000px;
    }

    &_gray_selection_area_separator {
        width: 1px;
        background-color: $stonex-light-gray-blue;
        margin: 0 24px;
    }

    &_selection_area_actions {
        display: flex;
        align-items: flex-end;
    }

    &_selection_area_save_button {
        margin: 0 24px 0 0;
    }

    &_last_saved_actions {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    &_last_saved {
        width: 100%;
        font-size: 12px;
        line-height: 15px;
        max-height: 50%;
        font-weight: 600;
        color: $alternative-neutral-text-color;
    }

    &_spot_price_reset_area {
        display: flex;
        margin-top: 12px;
        justify-content: center;

        & > div {
            padding: 8px 16px;
            background-color: $table-row-valid;
            margin-right: 12px;

            & p {
                margin: 0;
            }
        }
    }
}

.valorization_calculator_stream {
    font-size: 14px;
    line-height: 40px;
    display: flex;
    margin-top: 12px;

    &_row,
    &_summary_row {
        display: flex;
        justify-content: space-between;
        align-items: center;

        & p:first-child {
            white-space: nowrap;
        }

        & p {
            font-size: 12px;
            line-height: 15px;
            letter-spacing: 3px;
            text-transform: uppercase;
            margin: 0;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        & button {
            padding: 0;
        }
    }

    &_rows_area {
        width: calc(100% - 400px);
        min-width: 800px;

        & > div {
            height: 56px;
            line-height: 56px;
            display: flex;
        }

        & > div:nth-child(odd) {
            background-color: $stonex-light-gray-white;
        }
    }

    &_note {
        width: 451px;
        max-width: 100%;
        margin: 16px 0;
    }

    &_spot_price,
    &_spot_price_updated {
        & > div {
            height: 100%;
            align-items: center;
            padding: 0 16px;
            justify-content: flex-end;
        }

        & div > input {
            text-align: right;
            width: 88px;
        }
    }

    &_spot_price_updated {
        background-color: $table-row-valid;
        height: 100%;

        & div > input {
            border: 1px solid $chart-color-8;
        }
    }

    &_header_rows_area {
        width: calc(100% - 399px);
        margin-left: 200px;
        line-height: 32px;
        min-width: 800px;
        margin-bottom: -12px;
        & > div {
            height: 32px;
            display: flex;
        }
        background-color: $stonex-light-gray-white;
        border: 1px solid $stonex-light-gray-blue;
        border-bottom: none;
    }

    &_row,
    &_row:first-of-type {
        border-bottom: 1px solid $stonex-light-gray-blue;
    }

    &_row:first-of-type {
        border-top: 1px solid $stonex-light-gray-blue;
    }

    &_product_header {
        font-size: 12px;
    }

    &_product,
    &_product_header {
        margin-left: 16px;
        width: 20%;
        font-weight: 700;
    }

    &_spot_price,
    &_spot_price_updated,
    &_basis,
    &_cost_of_production,
    &_yield,
    &_subtotal,
    &_basis_updated,
    &_cost_of_production_updated,
    &_yield_updated,
    &_subtotal_updated {
        width: 16%;
        text-align: right;
        overflow: hidden;

        &_header {
            overflow: visible;
            text-wrap: nowrap;
            width: 16%;
            text-align: right;
            padding-right: 16px;
            font-weight: 700;
            font-size: 12px;
        }

        & button {
            padding-right: 16px;
        }

        & button svg {
            @include hover-cursor-pointer;
            font-size: 16px;
            color: $alternative-neutral-text-color;
            margin: 0 0 4px 8px;
        }
    }

    &_cost_of_production,
    &_cost_of_production_updated,
    &_cost_of_production_header {
        width: 20%;
    }

    &_yield,
    &_yield_updated,
    &_yield_header {
        min-width: 150px;
    }

    &_subtotal,
    &_subtotal_updated,
    &_subtotal_header {
        display: flex;
        justify-content: right;
        padding-right: 16px;
    }

    &_basis_updated,
    &_cost_of_production_updated,
    &_yield_updated {
        height: 100%;
        background-color: $table-row-valid;
    }

    &_edit_modal {
        display: flex;
        justify-content: space-between;
        gap: 24px;
    }

    &_final_price,
    &_final_price_updating {
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        width: 200px;
        border: 1px solid $stonex-light-gray-blue;
    }

    &_final_price_updating div {
        color: $stonex-light-gray-blue;
    }

    &_price {
        @include stonex-bold-font;
        font-size: 24px;
        line-height: 30px;
    }

    &_currency_unit {
        @include stonex-bold-font;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 200px;
        color: $alternative-neutral-text-color;
        font-size: 14px;
        line-height: 17px;
    }

    &_title_area {
        padding: 24px 16px;
        color: white;
        min-width: 200px;
        max-width: 200px;
        background-color: $stonex-valorization-blue;
        position: relative;

        & > button {
            @include hover-cursor-pointer;
            position: absolute;
            width: calc(100% - 32px);
            bottom: 24px;
            color: white;
            background-color: transparent;
            border: 1px solid $stonex-valorization-button-border;
            border-radius: 3px;
            padding: 0 23px;
            height: 30px;
            line-height: 30px;
        }

        & button svg {
            vertical-align: middle;
            color: white;
        }

        & > p {
            font-size: 18px;
            line-height: 23px;
            margin: 8px 0 0;
        }
    }

    &_image {
        width: 20px;
        margin-left: 10px;
    }
}

.valorization_list {
    &_table {
        border: 1px solid $stonex-light-gray-blue;
    }

    &_row {
        display: flex;

        &:nth-child(odd) {
            background-color: $neutral-color;
        }
    }

    &_header_cell,
    &_model_name,
    &_saved_by,
    &_last_updated,
    &_actions {
        padding-left: 16px;
        border-bottom: 1px solid $stonex-light-gray-blue;
        border-left: 1px solid $stonex-light-gray-blue;
    }

    &_header_cell:first-child,
    &_model_name {
        border-left: none;
    }

    &_model_name a,
    &_actions a {
        @include hover-cursor-pointer;
        color: $stonex-action-blue;
    }

    &_header_cell {
        @include stonex-bold-font;
        line-height: 40px;
        font-size: 12px;
    }

    &_model_name,
    &_saved_by,
    &_last_updated,
    &_actions {
        height: 32px;
        line-height: 32px;
        font-size: 14px;
    }

    &_model_name,
    &_header_cell:nth-of-type(1) {
        width: 40%;
    }

    &_saved_by,
    &_header_cell {
        width: 20%;
    }

    &_last_updated {
        width: 20%;
    }

    &_actions {
        width: 20%;
    }
}
