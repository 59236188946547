@import '../../../../Main.module.scss';

.global_dairy_trade_summary {
    @include common_table_borders;

    &_container {
        position: relative;
        min-height: 650px;

        [class^='LoadingSpinner_loading_container'] {
            position: absolute;
            top: 50%;
            height: 50%;
            transform: translateY(-50%);
        }
    }

    &_header:first-child {
        display: flex;
        justify-content: left;
        width: 23%;
    }

    &_header {
        @include common_table_header_text_spacing;
        @include common_table_header_display;
        justify-content: right;
        width: 11%;

        &_content {
            display: inline-flex;
            align-items: center;
            white-space: nowrap;

            &_text {
                margin-right: 20px;
            }
        }
    }

    &_row,
    &_header_row {
        display: flex;
    }

    &_value_column_header {
        font-weight: 700;
    }

    &_value_column_header,
    &_percent_column_header {
        @include common_table_header_display;
        padding: 0 17px;
        border-right: 1px solid $stonex-light-gray-blue;
        margin-bottom: 0;
        width: 23%;
        white-space: nowrap;
    }

    &_cell,
    &_cell_negative,
    &_cell_positive {
        @include common_table_header_display;
        border-right: 1px solid $stonex-light-gray-blue;
        height: 42px;
        margin-bottom: 0;
        width: 11%;
        padding-right: 17px;
        justify-content: right;
    }

    &_cell_negative {
        color: $stonex-negative-red;
    }

    &_cell_positive {
        color: $stonex-positive-green;
    }
}
