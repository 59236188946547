@import '../../../../Main.module.scss';

.positions {
    &_container {
        width: 100%;
    }

    &_table_container {
        width: 100%;
        max-width: 100%;
    }

    &_actions_row {
        display: flex;
        justify-content: space-between;
    }

    &_search_container {
        margin-bottom: 20px;
        position: relative;
    }

    &_actions_search_input {
        width: 320px;
        height: 40px;
        padding-left: 35px;
        border: 1px solid $stonex-light-gray-blue;
        border-radius: 3px;
        margin: 0px;
    }

    &_search_area_icon {
        position: absolute;
        z-index: 1;
        font-size: 12px;
        margin-left: 10px;
        margin-top: 7px;
        color: $stonex-medium-gray;
    }
}