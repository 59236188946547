@import '../../../../Main.module.scss';

.access_denied {

    &_info {
        background-color: $trial-block-color;
        color: white;
        padding: 11px 16px;
        width: 100%;
        margin: 20px 0px;
        border-radius: 3px;
        & img {
            width: 16px !important;
            margin-right: 10px;
        }
    }

    &_messages_container {
        display: flex;
    }    
    
    &_message_block {
        width: 48%;
        margin-right: 2%;
        margin-top: 40px;
        margin-left: 20px;
    }

    &_message_block h3, &_benefits_block h3 {
        @include stonex-bold-font;
    }

    &_message_block, &_message_block h3 {
        font-size: 18px;        
    }

    &_benefits_block, &_benefits_block h3 {
        font-size: 16px;        
    }

    &_benefits_block_wrapper {
        width: 48%;
        background-color: $deactivated-block-color;
        margin: 20px;
        margin-left: 2%;
        padding: 20px;
    }
    
    &_message_block, &_benefits_block_wrapper {
        line-height: 28px;
    }

    &_contact {
        &_main_container {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            width: 100%;
            gap: 30px;
        }

        &_phone_number_container {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            
            p {
                font-size: 14px;
                margin: 0px;
            }
            &_label {
                color: $stonex-light-gray;
            }
        }
    }

    &_access_denied_block {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        height: 100%
    }

    &_email {
        @include hover-cursor-pointer;
        position: relative;
        color: $stonex-blue-theme;
        border: none;
        background-color: transparent;
        padding: 0;
    }

    &_email svg {
        width: 15px;
    }

    &_email:hover &_tooltip_text {
        visibility: visible;
        background-color: $stonex-dark-gray;
        color: $stonex-off-white;
    }

    &_tooltip_text {
        position: absolute;
        display: block;
        width: 50%;
        bottom: 30px;
        visibility: hidden;
        padding: 3px 8px;
        border-radius: 3px;
        left: 210px;
    }
    
    &_container {
        margin: 20px;
        & img {
            width: 100%;
        }
    }

    @include media_wrapper_tablet {
        &_messages_container {
            display: block;
        }

        &_benefits_block_wrapper, &_message_block {
            width: 100%;
            margin: 20px 0px;
        }
    }

    @include media_wrapper_mobile {
        &_benefits_block_wrapper, &_message_block {
            line-height: 24px
        }
    }
}