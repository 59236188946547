@import '../../../../../../../Main.module.scss';

.combination_multipliers {
    &_column {
        display: flex;
        flex-direction: column;
        margin-top: 20px;
        margin-right: 20px;
    }

    &_month_label {
        width: 100px;
    }

    &_input_area {
        width: 200px;
    }

    &_month_label, &_input_area {
        margin-bottom: 10px;
    }
}