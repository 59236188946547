@import "../../../Main.module.scss";


$header-width: 240px;
$double-header-width: 480px;

.risk {

    &_title_container {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &_page_title {
        @include stonex-bold-font;
        line-height: 40px;
        font-size: 28px;
        margin-bottom: 24px;
    }

    &_tab {
        display: flex;
        flex-direction: row;
    }

    &_tab_item {
        border: none;
        background-color: transparent;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 10px 20px 9px;
        gap: 20px;
    }

    &_tab_item_selected {
        border: none;
        background-color: transparent;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 10px 20px 9px;
        gap: 20px;
        border-bottom: 2px solid $stonex-blue-theme;
    }

    &_request_and_chart_container {
        display: flex;
        width: 100%;
        flex-direction: row;
    }

    &_dropdown_inputs_container {
        background-color: $stonex-off-white;
        border: 1px solid $stonex-gray-red-hue;
        padding: 20px 20px 0px 20px;
        margin: 32px 0;
        width: 435px;
        box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
        border-radius: 3px;

        & div>div>div>div>div {
            background-color: white;
        }
    }

    &_dropdown_row,
    &_dropdown_conversions_row {
        display: flex;
    }

    &_dropdown_row,
    &_dropdown_conversions_row {
        width: 100%;
        margin-bottom: 32px;
    }

    &_chart_container {
        display: flex;
        width: 100%;
        min-width: 500px;
        margin: 32px 0 32px 32px;
        flex-direction: column;
        flex-grow: 1;
        flex-shrink: 1;
    }

    &_gray_divider {
        border-bottom: 1px solid $stonex-light-gray-blue;
        position: relative;
        right: 21px;
        width: calc(100% + 42px);
        margin-bottom: 16px !important;
    }

    &_grid_input_wrapper {
        margin: 5px;
        margin-bottom: 5px !important;
    }

    &_grid_wrapper {
        width: 100%;
    }

    &_key_valid {
        background-color: $table-row-valid;
        padding: 8px 16px;
        margin: 8px;
    }

    &_key_invalid {
        background-color: $table-row-invalid;
        padding: 8px 16px;
        margin: 8px;
    }

    &_table_key {
        font-size: 12px;
        line-height: 15px;
        align-items: center;
        justify-content: center;
        width: 100%;
        display: flex;
        text-align: center;
    }

    &_button_reset {
        @include stonex-bold-font;
        @include hover-cursor-pointer;
        font-size: 14px;
        line-height: 17.5px;
        padding: 11px 16px;
        border: 1px solid $stonex-light-gray-blue;
        background-color: $plainWhite;
        border-radius: 3px;
        align-items: center;
        text-align: center;
        letter-spacing: 0.07em;
        text-transform: uppercase;
    }

    &_chart_top_right {
        float: right;
        display: flex;
    }

    &_page_subheader {
        line-height: 23px !important;
    }

    &_half_width_left {
        width: 50%;
        min-width: calc(50% - 20px);
        margin-right: 20px;
    }

    &_half_width_right {
        width: 50%;
        min-width: calc(50% - 20px);
    }

    &_full_width {
        width: 100%;
    }

    &_text_input {
        height: 40px;
        margin-top: 1px;
    }

    &_input_label {
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
    }

    &_add_and_remove_contracts {
        display: flex;
        margin: 25px 0;
    }

    &_save_and_load_last {
        display: flex;
        margin-right: 20px;
        border-right: 1px solid $stonex-light-gray-blue;
    }

    &_save_and_load_last button,
    &_add_and_remove_contracts p:first-child, 
    &_add_and_remove_contracts button:nth-child(2) {
        margin-right: 20px;
    }

    &_add_and_remove_contracts p:first-child {
        font-size: 14px;
        line-height: 40px;
        color: $stonex-medium-gray;
        margin-bottom: 0px;
    }

    &_delete_icon {
        color: $stonex-medium-gray;
    }

    &_area_above_table {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        min-width: calc(100% - $double-header-width);
        width: calc(100% - 96px);
    }

    &_table {
        position: absolute;
        overflow-x: scroll;
        max-width: calc(100% - $double-header-width);
        border-right: 1px solid $stonex-light-gray-blue;
        left: $double-header-width;
    }

    &_table_wrapper_flex {
        display: flex;
        position: relative;
        min-width: calc(100% - $double-header-width);
        width: calc(100% - 96px);
    }

    &_net_physical_positions {
        @include hover-cursor-pointer;
        display: flex;
        align-items: center;
    }

    &_input_updated {
        border: 2px solid green; // TODO: Fix this.
    }

    &_tooltip {
        width: 20px;
        margin-left: 10px;
    }

    &_carousel {
        position: relative;
        height: 100%;
        width: 900px;
        height: 450px;

        &_chart {
            width: 100%;
            height: 450px;
        }
    }

    &_disclaimer {
        margin-top: 16px;
        color: $stonex-medium-gray;
        font-size: 10px;
        text-align: center;
    }
}


// Will be common amongst caclulator tables on  the Risk page.
.calculator_table {
    &_image {
        width: 20px;
        margin-left: 10px;
    }

    &_base_column_text_only,
    &_updated_column_text_only {
        padding-right: 16px !important;
    }

    &_flex_header_column,
    &_alternate_background,
    &_alternating_background_group:nth-child(even) &_base_column,
    &_alternating_background_group:nth-child(even) &_base_column_text_only,
    &_alternating_background_group:nth-child(even) &_updated_column_text_only,
    &_alternate_background_header {
        background-color: $table-row-dark;
    }

    &_base_background_header,
    &_alternate_background_header,
    &_dark_background_header,
    &_header {
        display: flex;
        align-items: center;
    }

    &_header_row {
        display: inline-flex;
        text-align: right;
        height: 56px !important;
    }


    &_alternating_background,
    &_alternate_background {
        display: flex;
        width: max-content;
    }

    &_alternate_background div,
    &_alternating_background div,
    &_base_column_text_only {
        text-align: right;
    }

    &_alternating_background div div input,
    &_alternate_background div div input {
        text-align: right;
        margin: 8px 16px;
    }

    &_alternating_background div,
    &_alternate_background div {
        display: flex;
        align-items: center;
    }

    &_flex_header_column,
    &_flex_subheader_column {
        width: $header-width;
        border-bottom: 1px solid $stonex-light-gray-blue;
        border-left: 1px solid $stonex-light-gray-blue;
        overflow: hidden;
    }

    &_flex_subheader_column div {
        padding: 0 0 0 17px;
    }

    &_flex_subheader_column div {
        width: 100%;
        border: 1px solid $stonex-light-gray-blue;
        border-left: none;
        height: 56px;
    }

    &_base_column_text_only,
    &_updated_column_text_only,
    &_base_column,
    &_cell_updated {
        min-width: 160px;
        max-width: 160px;
        height: 56px;
        overflow: hidden;
        border: 1px solid $stonex-light-gray-blue;
        display: inline-flex;
        align-items: center;
        justify-content: flex-end;
    }

    &_dark_background_header,
    &_header {
        @include stonex-bold-font;
        font-size: 14px;
        border-right: none;
        padding: 0 5px 0 17px;
        margin: 0;
    }

    &_dark_background_header {
        background-color: $deactivated-block-color;
    }

    &_cell_updated {
        background-color: $table-row-valid;
    }

    &_chart_bottom_bar {
        width: calc(100% - 96px);
        height: 51px;
        background-color: $table-row-dark;
    }
}

