@import '../../../../Main.module.scss';

.worker_logs {
    &_table {
        margin-top: 20px;
        border-collapse: collapse;
        display: table;
        table-layout: fixed;

        &_row {
            display: table-row;
        }

        &_cell {
            display: table-cell;
            padding: 5px 15px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            border: 1px solid $stonex-light-gray-blue;

            &_name {
                @extend .worker_logs_table_cell;
                width: 120px;
            }

            &_date {
                @extend .worker_logs_table_cell;
                width: 70px;
            }

            &_status {
                @extend .worker_logs_table_cell;
                width: 70px;
            }

            &_message {
                @extend .worker_logs_table_cell;
                min-width: 500px;
            }
        }

        &_header_cell {
            @extend .worker_logs_table_cell;
            background: $stonex-off-white;
            font-weight: bold;
            padding: 17px 15px;
        }
    }

    &_status_field {
        padding: 9px 5%;
        text-align: center;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 11px;
        border-radius: 3px;
    }
}
