@import '../../../../../Main.module.scss';

.market_indicator_actions {
    &_modal_input_wrapper {
        padding-bottom: 24px;
    }

    &_modal_review_history {
        &_reviewers {
            margin: 0 0 16px 0;
        }

        &_status {
            display: inline-block;
            min-width: 100px;
            padding: 4px 8px;
            border-radius: 3px;
            text-transform: uppercase;
            font-size: 11px;
            font-weight: 600;
            text-align: center;
        }

        &_status_approved {
            @extend .market_indicator_actions_modal_review_history_status;
            background-color: $active-block-color;
        }

        &_status_rejected {
            @extend .market_indicator_actions_modal_review_history_status;
            background-color: $expired-block-color;
        }

        &_table {
            width: 100%;
            border-collapse: collapse;
            display: table;

            &_wrapper {
                max-height: 500px;
                overflow-y: auto;
            }

            &_row {
                display: table-row;
                cursor: pointer;
            }

            &_cell {
                display: table-cell;
                padding: 5px 15px;
                border: 1px solid $stonex-light-gray-blue;
            }

            &_header_cell {
                @extend .market_indicator_actions_modal_review_history_table_cell;
                background: $stonex-off-white;
                font-weight: bold;
            }

            &_no_data {
                display: block;
                padding: 30px 15px;
                border: 1px solid $stonex-light-gray-blue;
                border-top: 0;
                text-align: center;
                width: 100%;
            }
        }
    }
}
