@import '../../../../Main.module.scss';

.futures_prices {
    &_table {
        &_container {
            width: 100%;
            max-width: 100%;
        }
    }

    &_chart_container,
    &_chart_container_small {
        display: flex;
        width: 100%;
        min-width: 100%;
        height: 650px;
        margin-top: 30px;
        margin-bottom: 30px;
        align-items: center;
        flex-grow: 1;
    }

    &_chart_container_small {
        height: 450px;
    }

    &_multiple_header_components_container {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        gap: 12px;
    }

    &_comparison_chart {
        @include stonex-bold-font;
        font-size: 18px;
        line-height: 45px;
        margin-right: 10px;

        &_sub_header {
            display: flex;
            margin-top: -9px;
            margin-bottom: -10px;
        }

        &_selection1 {
            font-weight: 400;
            font-size: 16px;
            margin-right: 14px;
            min-width: max-content;
        }

        &_selection2 {
            width: 100%;
            margin-top: -8px;

            & label {
                font-size: 14px;
                color: $stonex-primary-blue;
                font-weight: 700;
                font-family: $application-font-family;
            }
        }
    }
}