@import '../../../../Main.module.scss';

.origins_and_destinations {
    &_table_container {
        margin-top: 20px;
        height: calc(100% - 65px);
    }

    &_unit_of_measure {
        font-size: 10px;
        text-align: center;
        margin-bottom: 5px;
        margin-top: -10px;
    }
}

.origins_destinations_top_left_corner {
    background-color: white;
}
.data_source_container {
    margin-top: 24px;
}

.region_renderer {
    &_parent_div {
        display: flex;
        flex-direction: row;
        margin-left: -17px;
        position: fixed;
    }
    &_color {
        width: 6px;
        height: 44px;
        margin-right: 13px;
    }
    &_name {
        display: flex;
        justify-content: center;
    }
}
