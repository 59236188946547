@import '../../../../../Main.module.scss';

.valorization_action {
    &_selection_area_save_as_button {
        margin: 0 24px 0 0;
    }

    &_selection_area_save_as_button_alone {
        display: flex;
        align-items: flex-end;
        height: 78px;
    }

    &_add_streams {
        font-size: 14px;
        line-height: 40px;
        display: flex;

        & > button {
            @include hover-cursor-pointer;
            border: none;
            background-color: transparent;
            display: flex;
            margin-top: 25px;
            & > p {
                margin: 2px 0 0 8px;
            }

            & > img {
                align-self: center;
            }
        }
    }

    &_content_row {
        display: flex;
        justify-content: space-between;
        margin: 8px 0;
    }

    &_content_row_full_width {
        width: 100%;
    }

    // Milk solids.
    &_selection_area_milk_solids_title,
    &_selection_area_milk_solids_edit {
        height: 50%;
        align-content: center;

        & div {
            align-self: center;
        }

        & button {
            padding: 0;
        }
    }
    &_selection_area_milk_solids_edit {
        display: flex;
        font-weight: 700;

        & button svg {
            @include hover-cursor-pointer;
            font-size: 16px;
            color: $alternative-neutral-text-color;
            margin: 0 0 4px 8px;
        }
    }

    // Common modals.
    &_how_to_use {
        margin: 0 0 16px;
        line-height: 21px;
        font-weight: 400;
    }

    &_content {
        margin: 8px 0;
        max-height: 250px;
        overflow-y: scroll;
    }

    &_text_input_data_modal, &_text_input_milk_solids {

        & div {
            width: 100%;
            justify-content: right;
        }

        & div input {
            text-align: right;
            float: right;
            width: 144px;
        }

        & > p:last-of-type {
            align-content: center;
            margin-left: 8px;
            margin-bottom: 0;
            position: absolute;
            top: 10px;
            right: -20px;
        }
    }

    &_text_input_milk_solids {
        position: relative;
        right: 20px;
    }

    &_label_text {
        @include stonex-bold-font;
        font-size: 14px;
        line-height: 17.5px;
        align-self: center;
        margin: 0;
    }

    &_input_separator {
        width: 100%;
        border-bottom: 1px solid $stonex-light-gray-blue;
    }

    &_expanding_button_with_text {
        display: flex;

        & button {
            @include hover-cursor-pointer;
            background-color: transparent;
            border: none;
        }
    }
}
