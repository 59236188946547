@import '../../../Main.module.scss';

.feature_flags {
    &_user_type {
        margin-bottom: 20px;
    }

    &_row {
        width: 100%;
        display: flex;
        align-items: center;
    }

    &_save_button {
        margin-top: 20px;
    }
}
