@import '../../../../Main.module.scss';

.origins_and_destinations {
    &_header {
        display: flex;
        flex-direction: column;
        align-self: flex-end;
        margin-bottom: 0px;
    }

    &_donut_selection_buttons {
        display: flex;
        justify-content: space-between;
    }

    &_chart, &_table {
        margin-top: -8px;
        width: calc(50% - 10px);
    }

    &_chart {
        margin-left: 10px;
    }

    &_table {
        margin-right: 10px;
    }

}
