@import '../../../Main.module.scss';

.user_data {
    &_startup {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 96px;
        text-align: center;

        &_icon {
            width: 64px;
            height: 64px;
        }

        &_info {
            font-weight: 400;
            font-size: 16px;
            width: 420px;
            margin-bottom: 20px;
            text-align: center;
        }

        &_note {
            margin-top: 20px;
            font-weight: 400;
            font-size: 12px;
            width: 420px;
            text-align: center;
        }
    }

    &_file_select {
        &_header_container {
            display: flex;
            justify-content: flex-start;
            gap: 10px;

            & h2 {
                margin: 5px;
            }
        }

        &_container {
            margin: 20px 0;

            & a {
                color: $stonex-primary-blue;
            }

            &_description {
            }

            &_form {
                margin-top: 20px;
                display: flex;
                justify-content: flex-start;
                align-items: center;
                gap: 50px;

                &_file {
                    display: flex;
                    align-items: center;
                    padding: 8px;
                    margin: 4px 0;

                    &_name {
                        margin: 0 10px;
                        color: $stonex-primary-blue;
                    }

                    &_error {
                        color: $stonex-negative-red;
                        font-size: 12px;
                    }

                    &_icon_success {
                        width: 20px;
                        height: 20px;
                    }

                    &_icon_error {
                        width: 24px;
                        height: 24px;
                    }

                    &_icon_delete {
                        width: 12px;
                        height: 12px;
                    }

                    & button {
                        background: none;
                        border: none;
                        padding: 0;
                        cursor: pointer;
                        display: flex;
                        align-items: center;
                    }
                }
            }
        }
    }

    &_upload_form {
        &_header {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        &_content {
            width: 525px;

            & input {
                max-width: 100%;
            }

            &_container {
                display: grid;
                gap: 15px;
            }

            &_row {
                display: flex;
                gap: 15px;
            }

            &_cell {
                &_full_width {
                    flex: 1 1 100%;
                }

                &_half_width {
                    flex: 1 1 calc(50% - 7.5px);
                }
            }
        }

        &_actions {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-end;
            text-align: right;
            gap: 15px;
            padding: 0 15px 15px 15px;
        }
    }

    &_management {
        &_header_container {
            display: flex;
            justify-content: space-between;
            align-items: center;

            & h2 {
                margin: 5px;
            }
        }

        &_description {
            margin: 30px 0 50px 0;
        }
        &_table {
            margin-top: 20px;
            width: 100%;
            border-collapse: collapse;
            display: table;

            &_row {
                display: table-row;
            }

            &_cell {
                display: table-cell;
                padding: 5px 15px;
                border: 1px solid $stonex-light-gray-blue;
            }

            &_header_cell {
                @extend .user_data_management_table_cell;
                background: $stonex-off-white;
                font-weight: bold;
            }

            &_footer {
                display: flex;
                justify-content: flex-end;
                gap: 10px;
                background: $stonex-off-white;
                padding: 5px 15px;
                border-left: 1px solid $stonex-light-gray-blue;
                border-right: 1px solid $stonex-light-gray-blue;
                border-bottom: 1px solid $stonex-light-gray-blue;

                & button {
                    background: none;
                    border: none;
                    cursor: pointer;
                }
            }

            &_actions {
                color: $stonex-primary-blue;

                & button {
                    background: none;
                    border: none;
                    padding: 0 5px;
                    cursor: pointer;
                    color: $stonex-primary-blue;
                }
            }
        }
    }
}
