@import '../../../../Main.module.scss';

.show_more_accordian {
    &_container {
        padding: 0;
        height: max-content;
        overflow: auto;
    }

    &_more_or_less {
        position: relative;
        bottom: 7px;
    }

    &_pointer_on_hover:hover {
        cursor: pointer;
    }

    &_additional_message {
        float: right;
    }
}
