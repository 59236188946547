@import '../../../../Main.module.scss';

.donut_chart {
    &_percent,
    &_value {
        font-weight: 600;
        font-size: 13px;
        margin-right: 5px;
        margin: 0;
    }

    &_value {
        color: $primary-text-color;
    }

    &_percent {
        color: $stonex-positive-green;
    }

    &_lable_root {
        justify-content: end;
    }

    &_country_name {
        font-family: $application-font-family;
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 0px;
        line-height: 0;
        text-align: right;
        color: $stonex-dark-gray;
    }

    &_data_and_percentage_wrapper {
        display: flex;
    }
}
