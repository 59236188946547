*, *::before, *::after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

body {
    margin: 0;
    height: 100%;
    font-size: 14px;
    line-height: 1.5715;
  }
  
  ::-webkit-scrollbar {
    width: 14px;
    height: 14px;
  }
  
  ::-webkit-scrollbar-thumb, html ::-webkit-scrollbar-thumb {
    border-radius: 22px;
    background-color: rgba(177, 177, 177, 0.4);
    height: 16px;
    border: 4.5px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
  }
  
  ::-webkit-scrollbar-corner, html ::-webkit-scrollbar-corner{
    background-color: rgba(0, 0, 0, 0);
  }
  
  .ag-body ::-webkit-scrollbar {
    width: 14px;
    height: 14px;
  }
  
  a {
    text-decoration: none;
  }

  p, h3, h4 {
    margin-top: 0px;
  }
  
  @media print {
    div {
      overflow: visible !important;
      max-width: 100%;
    }
  
    #mono-header {
      display: none;
    }
  
    #mono-react-grid-layout {
      .react-grid-layout {
        .react-grid-item[style] {
          transform: none !important;
        }
      }
    }
  }
  
  // This will be rendered when mono is NOT rendered.
  #root {
    display: flex;
    flex-flow: column;
    height: 100%;
    font-family: Mulish, Arial, sans-serif;
    min-width: 375px;
  }
  