@import '../../../Main.module.scss';

.acknowledgements {
    padding: 50px 20px;
    overflow-x: scroll;
    height: 100%;

    &_modal {
        box-shadow: 0px 16px 32px rgba(0, 0, 0, 0.2);
        background-color: white;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 935px;
        min-width: calc(80% - 200px);
        border-radius: 3px;
        display: flex;
        justify-content: space-between;
        flex-direction: column;
    }

    &_decline_button,
    &_accept_button {
        @include hover-cursor-pointer;
        @include justify-and-align-flex-items-center;
        position: relative;
        text-transform: uppercase;
        display: flex;
        flex-direction: row;
        padding: 11px 16px;
        width: 93px;
        height: 40px;
        float: right;
        border-radius: 3px;
    }

    &_content_container {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        gap: 15px;
        margin-left: 13px;
        margin-top: 20px;
        max-height: 515px;
        overflow-y: auto;

        &_header {
            display: flex;
            flex-direction: column;
            width: 100%;
            justify-content: flex-start;

            p {
                margin-bottom: 0;
            }
        }

        &_title {
            display: flex;
            width: 100%;
            flex-direction: column;
            align-items: center;

            h1 {
                font-size: 22px;
            }
        }
    }

    &_button_container {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 20px;
        margin-bottom: 25px;
        margin-right: 25px;
    }

    &_decline_button {
        margin-right: 24px;
        background-color: $plainWhite;
        border: 1px solid $stonex-light-gray-blue;
    }

    &_accept_button {
        border: none;
        color: $plainWhite;
        background: $stonex-blue-theme;
        margin-right: 24px;
    }

    &_top_section {
        position: absolute;
        width: 100%;
        height: 40px;
        background: $stonex-blue-theme;
        border-radius: 3px 3px 0px 0px;
    }

    &_inner_window {
        padding: 20px 30px 20px;
        max-height: 70%;
        overflow-y: scroll;
        border: 1px solid $session-page-red-gray;
        margin: 24px;
        font-weight: 700;
        font-size: 12px;
        line-height: 15px;
    }

    &_h3 {
        @include stonex-bold-font;
        margin-left: 24px;
        margin-top: 50px;
        font-style: normal;
        font-size: 21px;
        line-height: 40px;
        display: flex;
        align-items: center;
    }
}

.topBar {
    width: 100%;
    display: flex;
    justify-content: space-between;
    background-color: $stonex-blue-theme;
}

.topBar img {
    width: 12px;
    margin-left: 15px;
}

.topBar button {
    background-color: transparent;
    border: none;
    padding: 0px;
    color: white;
    margin: 10px 10px 10px 0px;
}

.session_in_use {
    &_container {
        @include vertical-and-horizontal-center-align;
        margin: 32px;
        width: calc(100% - 64px);
    }

    &_p {
        text-align: center;
    }

    &_contact_header {
        font-size: 16px;
        padding: 0 5px;
    }

    &_contact_header_sentence {
        font-size: 16px;
        display: flex;
        justify-content: center;
        align-items: flex-start;
    }

    &_block,
    &_block button,
    &_contact_container {
        text-align: center;
        margin: auto;
    }

    &_block button {
        margin-bottom: 15px;
    }

    &_contact_header_sentence button {
        padding-top: 0;
    }

    &_link {
        text-decoration: underline;
    }

    &_contact {
        line-height: 8px;
        font-size: 14px;
        &_label {
            color: $stonex-light-gray;
        }
    }
}

.access_pending {
    @include vertical-and-horizontal-center-align;

    &_border {
        margin: 0 auto;
        border: 1px solid $stonex-light-gray-blue;
        border-radius: 4px;
        width: calc(100% - 1000px);
        min-width: 650px;
    }

    &_text_container {
        margin: 64px;
    }

    &_title {
        @include stonex-bold-font;
        text-align: center;
        font-size: 21px;
        line-height: 28px;
        margin-bottom: 30px;
    }

    &_text {
        text-align: center;
        font-size: 18px;
    }
}

@media (max-width: 767.98px) {
    .acknowledgements {
        &_modal {
            width: 350px;
        }
    }
}

.company_registration {
    &_agreement {
        &_content {
            max-height: 70vh;
            max-width: 80vw;
            overflow-y: auto;
            padding: 20px;

            h2 {
                text-align: center;
            }
        }

        &_buttons {
            margin-top: 30px;
            display: flex;
            justify-content: flex-end;
            gap: 20px;
        }
    }

    &_text {
        &_subheader {
            margin-top: 30px;
        }

        &_preformatted {
            white-space: pre-wrap;
            word-wrap: break-word;
        }
    }

    &_form {
        width: 451px;

        &_field {
            margin-top: 30px;

            &_checkbox_container {
                display: flex;
                align-items: center;
                justify-content: flex-start;

                label {
                    margin-right: 0;
                }
            }
        }

        &_buttons {
            margin-top: 30px;
            display: flex;
            justify-content: flex-end;
            gap: 20px;
        }
    }
}
