@import '../../../../Main.module.scss';

.actions_cell {
    &_button {
        @include hover-cursor-pointer;
        border: none;
        background-color: transparent;
        color: $stonex-blue-theme !important;
        padding: 0px;
        line-height: 16px;

        &:not(:first-child) {
            border-left: 1px solid $stonex-light-gray-blue;
            padding-left: 5px;
            margin-left: 5px;
        }
    }
}
