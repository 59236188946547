.update_permission {
    &_submit_action_row {
        width: 451px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 20px;
    }

    &_input {
        width: 451px;
    }
}