@import '../../../../Main.module.scss';

.spread_calculator {
    &_request_and_chart_container {
        display: flex;
        width: 100%;
        flex-direction: row;
        height: 490px;
    }

    &_dropdown_inputs_container {
        background-color: $stonex-off-white;
        border: 1px solid $stonex-gray-red-hue;
        padding: 20px 20px 0px 20px;
        margin: 32px 0;
        width: 435px;
        box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.2);
        border-radius: 3px;

        & div>div>div>div>div {
            background-color: white;
        }
    }

    &_dropdown_row,
    &_dropdown_conversions_row {
        display: flex;
    }

    &_dropdown_row,
    &_dropdown_conversions_row {
        width: 100%;
        margin-bottom: 32px;
    }

    &_chart_container {
        display: flex;
        width: 100%;
        min-width: 500px;
        margin: 32px 0 32px 32px;
        flex-direction: column;
        flex-grow: 1;
        flex-shrink: 1;
    }

    &_gray_divider {
        border-bottom: 1px solid $stonex-light-gray-blue;
        position: relative;
        right: 21px;
        width: calc(100% + 42px);
        margin-bottom: 16px !important;
    }

    &_grid_input_wrapper {
        margin: 5px;
        margin-bottom: 5px !important;
    }

    &_grid_wrapper {
        margin-bottom: 16px;
        border-bottom: 1px solid $stonex-light-gray-blue;
    }

    &_key_valid {
        background-color: $table-row-valid;
        padding: 8px 16px;
        margin: 8px;
    }

    &_key_invalid {
        background-color: $table-row-invalid;
        padding: 8px 16px;
        margin: 8px;
    }

    &_table_key {
        font-size: 12px;
        line-height: 15px;
        align-items: center;
        justify-content: center;
        width: 100%;
        display: flex;
        text-align: center;
    }

    &_button_reset {
        @include stonex-bold-font;
        @include hover-cursor-pointer;
        font-size: 14px;
        line-height: 17.5px;
        padding: 11px 16px;
        border: 1px solid $stonex-light-gray-blue;
        background-color: $plainWhite;
        border-radius: 3px;
        align-items: center;
        text-align: center;
        letter-spacing: 0.07em;
        text-transform: uppercase;
    }

    &_chart_top_right {
        float: right;
        display: flex;
    }

    &_page_subheader {
        padding-top: 40px;
        line-height: 23px !important;
    }

    &_half_width_left {
        width: 50%;
        min-width: calc(50% - 20px);
        margin-right: 20px;
    }

    &_half_width_right {
        width: 50%;
        min-width: calc(50% - 20px);
    }

    &_input_label {
        font-weight: 600;
        font-size: 14px;
        line-height: 18px;
    }
}

.ag_grid {

    &_input {
        vertical-align: middle;
        text-align: right !important;
        border: 1px solid #cfd3d3 !important;
        border-radius: 3px;
        width: 150px;
        margin: 5px 10px;
        height: calc(100% - 10px);
        line-height: 30px !important;
        background-color: white !important;
    }

    &_header_align {
        border: 1px solid var(--ag-border-color, $stonex-light-gray);
        border-bottom: none;
        text-transform: uppercase;

        div {
            justify-content: center;
        }
    }

    &_corner {
        background-color: white;

        div {
            visibility: hidden;
        }
    }

    &_subheader {
        border-top: 1px solid var(--ag-border-color, $stonex-light-gray);
        justify-content: flex-end;
    }
}