@import '../../Main.module.scss';

.sidebar {
    &_blue {
        position: relative;
        background: $sidebar-background;
        font-size: 14px;
        width: 200px;
        height: 100%;
        font-family: 'Mulish';
        font-weight: 500;
        line-height: 40px;
        display: block;
    }

    &_header {
        padding: 10px 30px 0 30px;
    }

    &_navItem {
        padding: 0 2px 0 30px;
        background-color: transparent;
        width: 100%;
        border: none;
        color: $plainWhite;
        position: relative;
        line-height: 40px;
        &:hover {
            text-decoration: none;
            background-color: $sidebar-element-hover;
            width: 100%;
            color: $plainWhite;
        }
    }

    &_navItem_selected {
        padding: 0 2px 0 30px;
        background-color: transparent;
        width: 100%;
        border: none;
        color: $plainWhite;
        position: relative;
        line-height: 40px;
        &:hover {
            text-decoration: none;
            width: 100%;
            color: $plainWhite;
        }
    }

    &_upper_nav,
    &_lower_nav {
        padding-top: 20px;
        padding-bottom: 20px;
    }

    &_navItem_selected {
        font-weight: 700;
        padding: 0 2px 0 30px;
        background-color: $stonex-sidebar-selected;
        width: 100%;
    }

    &_blue_colorblock {
        background-color: $stonex-sidebar-colorblock;
        width: 10px;
        padding: 0;
        margin: 0;
        top: 15px;
        display: flex;
        align-items: center;
        height: 20px;
        position: relative;
    }

    &_navigation_divider {
        border-bottom: 1px solid gray;
        width: 90%;
        margin-left: 5%;
        margin-top: 10px;
        margin-bottom: 10px;
    }

    &_navigation_header {
        color: $stonex-disabled-light-gray;
        text-transform: uppercase;
        font-size: 10px;
        font-weight: 800;
        line-height: 20px;
        margin: 20px 0 10px;
        padding: 0 30px;
        letter-spacing: 1px;
    }

    &_content {
        padding-top: 20px;
        width: inherit;
        overflow-x: auto;
    }
}

@media (max-width: 768px) {
    .sidebar {
        &_blue {
            width: 100%;
        }
        &_content {
            position: unset;
        }
    }
}

@media (max-width: 768px) {
    .responsive_sidebar {
        display: block;
        width: 100%;
        background-color: $sidebar-background;
        height: 80px;
        padding-top: 18px;
        position: fixed;
        z-index: 9;
        text-align: center;

        & div {
            text-align: left;
            width: 90%;
        }
        & div div {
            width: 100%;
        }
    }
    .sidebar_dropdown {
        width: 60%;
        margin-left: 24px;
        border: 1px solid white;
        background-color: white;
        border-radius: 3px;
        height: 42px;
        .dropdown {
            text-align: left !important;
        }
    }
}

@media (max-width: 480px) {
    .responsive_sidebar {
        display: inline-block;
        width: 100%;
        background-color: $sidebar-background;
        height: 80px;
        text-align: -webkit-center;
        padding-top: 18px;
        position: fixed;
        z-index: 9;
    }
    .sidebar_dropdown {
        width: 90%;
        border: 1px solid white;
        background-color: white;
        border-radius: 3px;
        height: 42px;
        .dropdown {
            text-align: left !important;
        }
    }
}
