@import "../../../Main.module.scss";

.outlook {

    &_page_title {
        font-weight: bold;
        font-size: 28px;
        margin-bottom: 15px;
    }
    
    &_flex_container {
        display: flex;
        flex-wrap: wrap;
        min-width: 750px;
    }

    &_column {
        width: 33%;
        flex-grow: 1;
        min-width: 250px;
        flex-wrap: nowrap;
    }

    &_subheader {
        font-size: 16px;
        font-weight: 700;
    }

    &_internal_link {
        @include hover-cursor-pointer;
        background-color: transparent;
        border: none;
        display: block;
        font-size: 14px;
        font-weight: 400;
        color: $stonex-blue-theme;
        padding: 3px 0px 0px 0px;
        width: max-content;
    }
}

@media (max-width: 480px) { 
    .outlook {
        margin-top: 80px;
  }
} 
@media (min-width: 480px) and (max-width: 768px) {
    .outlook {
        margin-top: 80px;
  }
}
