@import "../../../Main.module.scss";

.notifications {

    &_container_anchor_origin_bottom_left {
        position: fixed;
        left: 32px;
    }
    
    &_icon_close {
        color: white;
    }

    &_green_success {
        background-color: $stonex-snackbar-green;
    }
}
