@import "../../Main.module.scss";

.indicator {
    display: flex;
    flex-direction: row;
    align-content: center;
    min-width: 260px;
    height: 87px;
    border-radius: 9px;
    box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.2);

    &_info_container {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        padding: 8px 8px 8px; 
        width: 100%;
    }

    &_info_top_container {
        display: flex;
        align-items: flex-end;
        gap: 10px;
    }

    &_info_top_container  p {
        margin-bottom: 0px;
    }

    &_title, &_currency {
        padding: 0;
        margin: 0;
    }

    &_title {
        line-height: 15px;
        font-size: 12px;
        font-weight: 700;
    }

    &_indicator_number {
        vertical-align: baseline;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
    }

    &_currency {
        line-height: 12.5px;
        font-size: 10px;
        padding-right: 7px;
        color: grey;
        padding-top: 1px;
        padding-bottom: 2.5px;
        height: 12.5px;
    }

    &_year_over_year {
        color: $stonex-medium-gray;
        font-size: 10px;
        font-weight: 400;
    }
    
    &_percent_change {
        line-height: 20px;
        vertical-align: baseline;
        font-weight: 700;
        font-size: 13px;
    }

    &_change_neutral {
        color: $stonex-blue-theme;
    }

    &_bottom_row {
        display: flex;
        justify-content: space-between;
        color: $stonex-medium-gray;
        font-size: 10px;
        font-weight: 400;
        width: 100%;

        p {
            margin-bottom: 0;
        }
    }

    &_date_and_frequency {
        display: flex;
        justify-content: flex-start;
    }

    &_price_frequency {
        padding-right: 8px;
    }

    &_arrow_icon_positive svg, &_arrow_icon_negative svg, &_arrow_icon_neutral svg  {
        font-size: 1.25rem
    }

    &_arrow_icon_positive {
        color: $stonex-positive-green;
    }

    &_arrow_icon_negative {
        color: $stonex-negative-red;
    }

    &_arrow_icon_neutral {
        color: $stonex-medium-gray;
    }

    &_arrow_box_negative, &_arrow_box_positive, &_arrow_box_neutral {
        @include justify-and-align-flex-items-center;
        display: flex;
        border-radius: 0px 9px 9px 0px;
        padding: 7px;
    }

    &_arrow_box_negative {
        background-color: $negative-light-red;
    } 
    
    &_arrow_box_positive {
        background-color: $positive-light-green;
    } 
    
    &_arrow_box_neutral {
        background-color: $neutral-color;
    }

    @include media_wrapper_mobile {
        border: 1px solid grey;
        width: max-content;
        height: auto;
        border-radius: 6px;
        padding: 8px;
        line-height: 23px;
        
        &_flex_left {
            padding-right: 4px;
        }
    }

}

