@import '../../../../../Main.module.scss';

.valorization_chart_carousel {
    position: relative;
    margin: 32px 0;
    height: 650px;

    &_chart {
        width: 100%;
        height: 650px;
    }
}

.valorization_net_return {
    &_select {
        margin-right: 12px;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        gap: 12px;
    }

    &_select_dropdown {
        width: 250px;
    }
}
