@import "../../Main.module.scss";

.alertPage {
    &_container {
        @include justify-and-align-flex-items-center;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        height: calc(100% - 63px);
        display: flex;
        z-index: 10000;
    }
    
    &_container .AlertPage {
        @include justify-align-with-text-center; 
        z-index: 1;
        padding: 20px;
        width: 30%;
        height: 20%;
        background: $plainWhite;
        display: flex;
        border-radius: 5px;
        position: relative;
        animation: animate 0.3s;
        font-family: 'Mulish';
    }
}
