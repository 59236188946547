.demeter__menu {
    border: 1px solid #F3F4F4 !important;
    width: 269px  !important;
    line-height: 40px !important;
    height: 476px;
    right: 20px;
}

.ReactModal__Overlay, .ReactModal__Body--open {
    background-color: rgba(0, 0, 0, 0.50) !important;
    align-items: center;
    justify-content: center;
}

.ReactModal__Content {
    inset: 0px !important;
    min-width: 775px;
    height: 580px;
    margin: 5% 15%;
}

@media (min-height: 866px) {
    .ReactModal__Content {
        height: 750px;
    }
  }
