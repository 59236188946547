@import '../../../Main.module.scss';

.brand_card {

    &_full, &_simplified {
        background-color: white;
        box-shadow: 1px 1px 4px $stonex-boxshadow-black;
        border-radius: 4px;
    }

    &_full {
        padding: 20px;
    }
    
    &_simplified {
        @include hover-cursor-pointer;
        padding: 16px;
    }

    &_center {
        text-align: center;

        & div {
            align-items: center;
        }
    }

    &_branding, &_branding_simplified {
        font-weight: 600;
    }

    &_branding {
        font-size: 20px;
    }

    &_title_simplified {
        font-size: 16px;
    }
    
    &_branding_simplified {
        font-size: 12px;
        line-height: 15px;
    }

    &_title, &_title_simplified {
        @include stonex-bold-font;
        margin-top: 8px;
        margin-bottom: 18px;
    }

    &_title {
        font-size: 24px;
        line-height: 30px;
    }

    &_title_simplified {
        line-height: 20px;
    }
}