@import '../../../../Main.module.scss';

.email {
    &_link {
        text-decoration: underline;
    }

    &_copy_container {
        display: flex;
        justify-content: center;
        align-items: flex-end;
        gap: 5px;
    }
}
