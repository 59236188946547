@import "../../Main.module.scss";

.footer {
    height: 63px;
    width: 100%;
    border-top: 1px solid $footer-gray-border;
    display: block;
    
    &_vertical_center {
        margin: 0;
        position: relative;
        top: 50%;
        -ms-transform: translateY(-50%);
        transform: translateY(-50%);
        margin-left: 10px;
        font-size: 12px;
        margin-top: 3px;
        color: $primary-text-color;
    }

  
    &_margin_adjustment {
        margin: 0 0 0 10px;
        font-size: 12px;
        margin-left: 20px;
    }
    
    &_link_text_style {
        color: $stonex-blue-theme;
        font-size: 12px;
    }
    
    &_link_vertical_line {
        font-size: 12px;
        margin-left: 5px;
        margin-right: 5px;
    }

    &_release {
        font-size: 12px;
    }
    &_link_vertical_line_production {
        color: transparent;
        font-size: 12px;
        margin-left: 5px;
        margin-right: 5px;
    }
    &_release_production {
        color: transparent;
        font-size: 12px;
    }
    
    @media (max-width: 768px) {
        &_link_vertical_line, &_link_text_style {
            display: none;
        }    
    }
    
    @media (min-width: 320px) and (max-width: 480px) {
    
            height: 63px;
            width: 100%;
            border-top: 1px solid $footer-gray-border;
            display: block;
            margin-bottom: 20px;
            
            &_margin_adjustment {
                margin: 10px 0 0 20px;
            }
            
            &_links {
                display: none;
            }
        
    }
}



