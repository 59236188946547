@import "../../Main.module.scss";

.alert_modal {
  &_container {
    @include justify-and-align-flex-items-center;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height: 100vh;
    display: flex;
    background: rgba(0, 0, 0, 0.7);
    z-index: 10000;
  }

  &_box {
    @include justify-align-with-text-center;
    z-index: 1;
    padding: 20px;
    width: 40%;
    height: 25%;
    background: white;
    display: flex;
    border-radius: 5px;
    position: relative;
    animation: animate 0.3s;
    font-family: 'Mulish';
  }

  &_button {
    @include hover-cursor-pointer;
    @include stonex-bold-font;
    position: absolute;
    top: 7px;
    right: 7px;
    padding: 8px 11px;
    background: transparent;
    border: none;
    outline: none;
    border-radius: 2px;
  }
}

  @keyframes animate {
    from {
      transform: scale(0.5);
    }
    to {
      transform: scale(1);
    }
  }