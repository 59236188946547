@import '../../../../../Main.module.scss';

.button_with_tooltip {
    width: 100%;
    text-align: center;
    padding: 8px;
    color: $stonex-action-blue;
    background: white;
    font-weight: 600;
    border: 1px solid $stonex-light-gray-blue;
    border-radius: 4px;
    box-shadow: 2px 2px 5px $stonex-boxshadow-black;
    transition: box-shadow 0.3s ease;
    cursor: pointer;
}
