@import "../../../../../Main.module.scss";

$blue-gradient-1: #0d1775;
$blue-gradient-2:  #1383f4;

.registration_button {
   
    &_link_blue_primary, &_link_white_primary, &_link_white_secondary, &_link_white_pure {
        font-size: 16px;
        line-height: 20px;
        text-align: center;
        border: none;
    }


    &_blue_primary, &_white_primary, &_white_secondary, &_white_pure {
        border-radius: 4px;
        padding: 14px 24px 14px 24px;
        gap: 8px;
        cursor: pointer;

        &:hover {
            @include hover-cursor-pointer;
            &:disabled {
                cursor: default;
                background: $deactivated-block-color;
            }
        }   
        &:disabled {
            border: none;
            background: $deactivated-block-color;
        }    
        &:disabled .button_link_blue, 
        &:disabled .button_link_white {
            color: $stonex-disabled-light-gray !important;
        }
    }
    
    &_blue_primary {
        background-image: linear-gradient($blue-gradient-1, $blue-gradient-2);
        border: none;
      
        &:hover {
            background-image: none;
            background-color: $blue-gradient-1;
        }
    }

    &_link_blue_primary {
        color: white !important;
    }

    &_white_primary {
        background-color: white;
    }

    &_link_white_primary {
        background: linear-gradient($blue-gradient-1, $blue-gradient-2);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    &_white_secondary {
        background-color: transparent;
        border: none;
        border: 1px solid $blue-gradient-2;
    }

    &_link_white_secondary {
        background: linear-gradient($blue-gradient-1, $blue-gradient-2);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    &_white_pure {
        background-color: transparent;
        border: 1px solid white;
    }

    &_link_white_pure {
        color: white
    }

}