@import '../../../../../Main.module.scss';

.combination_tabs {
    &_container {
        margin-top: 32px;
    }

    &_plus_button {
        @include hover-cursor-pointer;
        border: none;
        background-color: transparent;
        border-bottom: 1px solid $stonex-light-gray-blue;
    }

    &_plus_button {
        padding: 0 15px;
    }
    
}

.indicator_add_and_edit {
    position: relative;

    &_details, &_template, &_data_source {
        margin-top: 32px;
    }

    &_page_container {
        position: relative;
        margin: 32px;
        width: 100%;
        height: 100%;
    }

    &_row {
        margin-top: 16px;
        display: flex;

        & p {
            display: block;
            overflow: hidden;
            text-overflow: ellipsis;
        }


        &_technical_input div, & div, &_factor_name, &_text_input {
            width: 280px;
        }

        &_technical_input  {
            margin-bottom: 32px;
            display: flex;
            width: 280px;
            gap: 32px;
    
            & p {
                position: absolute;
                top: 75px;
            }
        }

        &_spacer {
            margin-top: 32px;
        }

    }

    &_multipliers {
        display: flex;
    }

    &_column {
        display: flex;
        flex-direction: column;
        margin-top: 20px;
        margin-right: 20px;
    }

    &_position {
        position: relative;
    }

    &_subfactor_name {
        margin: 10px 0;
    }

    &_joined_buttons {
        display: flex;
        justify-content: space-between;
        margin-top: 20px;
    }

    &_input_wrapper {
        position: relative;
    }    

    &_long_term {
        display: inline-flex;
    }

    &_dropdown {
        margin-right: 32px;
    }

    &_action_buttons_row {
        position: relative;
        display: flex;
        margin-top: 20px;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        gap: 20px;
    }

    &_action_buttons_test {
        position: absolute;
        left: 0;
    }

    &_save_and_cancel {
        display: flex;

        & button:first-of-type, button:nth-of-type(2) {
            margin-right: 15px;
        }
    }

    &_manual_entry {
        border-radius: 3px;
        width: 100%;
        border: 1px solid $stonex-light-gray-blue;
        height: 100px;
    }
    
    &_manual_adding_section {
        margin-right: 32px;
    }
    
    &_manual_adding_section div div {
        width: unset;
    }

    &_manual_adding_section div div:first-of-type {
        width: 100%;
    }

    &_manual_adding_section div div div button  {
        float: right;
    }

    &_manual_apply_button {
        margin-top: 15px;
    }

    &_manual_apply_button button {
        float: right;

        & div {
            width: max-content;
        }
    }

    &_markets_chart {
        margin-top: 16px;
        height: 400px;
    }
    
    &_image {
        margin-top: 16px;
        height: 400px;
        width: 800px;
    }

    &_markets_secondary_chart {
        margin-top: 16px;
        height: 600px;
    }

    &_markets_chart_and_controls {
        max-width: 797px;
    }

    &_close_icon {
        @include hover-cursor-pointer;
        position: absolute;
        right: 20px;
    }

    &_line_break {
        width: 100%;
        max-width: 650px;
        margin-top: 32px;
        border-bottom: 1px solid $stonex-disabled-light-gray;
    }

    &_add_data_source {
        @include hover-cursor-pointer;
        margin-top: 15px;
        display: flex;
        background-color: transparent;
        border: none;
        & img {
            height: 16px;
            margin-right: 5px;
            margin-top: 3px;
        }
    }

    &_close_icon_container {
        max-width: 650px;

        & svg {
            @include hover-cursor-pointer;
            position: relative;
            top: 32px;
            left: 630px;
            color: $stonex-disabled-light-gray;
        }
    }
}