@import "~ag-grid-community/src/styles/ag-grid.scss";
@import "~ag-grid-community/src/styles/ag-theme-alpine/sass/ag-theme-alpine-mixin";
@import "../../Main.module.scss";

.ag-theme-demeter-alpine {
    @include ag-theme-alpine(());
    
    // Redefine ag-grid variables.
    // It seems that the scss compiler is unable to decipher this variable when it is outside of the other classes (only 
    // applies to this one instance).
    --ag-value-change-value-highlight-background-color: #FFFAD3;
    --ag-header-background-color: #F7F7F7;
    --ag-selected-row-background-color: #DBE5EC;
    --ag-control-panel-background-color: #F7F7F7;
    --ag-alpine-active-color: #2196f3;
    --ag-selected-tab-underline-color: #2196f3;

    .ag-checkbox-input-wrapper.ag-checked::after {
        color: $stonex-blue-theme !important;
    }

    .ag-row {
        border-width: 0px;
    }

    .ag-cell-label-container {
        color: $primary-text-color;
    }

    [col-id="isSelected"], [col-id="actions"] {
        --ag-value-change-value-highlight-background-color: none;
    }

    [col-id="Ask Price"] {
        color: red;
    }

    [col-id="Bid Price"] {
        color: green;
    }

    .ag-ltr .ag-cell {
        border-right: 1px solid rgb(207, 211, 211);
    }

    .ag-header-row {
        min-height: 55px;
        max-height: 200px;
    }

    .ag-right-aligned-header {
        justify-content: flex-end;
    }
    
    .ag-header-cell {
        padding-left: 5px;
        padding-right: 5px;
    }

    .ag-header-cell-flex-start {
        justify-content: flex-start;
    }

    .ag-root-wrapper {
        border: none !important;
        overflow-x: auto;
    }

    .ag-cell-label-container {
        overflow: visible;
        padding-left: 5px;
        padding-right: 5px;
        table-layout: fixed;
        .ag-icon {
            margin: 4px 5px 0 0;
          }
    }

    .ag-header-icon {
        margin-left: 0;
    }

    .ag-header-cell-text {
        white-space: normal;
    }

    .ag-row-hover {
        background-color: $ag-row-hover;
        cursor: text;
    }

    .ag-row-selected {
        background-color: $ag-row-selected !important;
    }

    .ag-cell-focus {
        border-left: none !important;
        border-top: none !important;
        border-bottom: none !important;     
    }

    // Only should effect the origins/destinations table
    .ag-floating-bottom {
        border-top: none;
    }

    .ag-cell-inline-editing {
        border: 1px solid #CFD3D3 !important;
        width: 180px;
        border-radius: 3px;
        margin: 5px 10px;
        height: calc(100% - 10px);
        background-color: white;
    }

    .ag-text-field-input {
        text-align: right;
    }

    .ag-header-gdt-summary-table {
        padding-left: 0px;
    }
    
    .ag-header-gdt-summary-table, .ag-header-gdt-summary-table-right {
        background-color: $plainWhite;
        font-weight: 700;
        color: $stonex-medium-gray;
        font-size: 12px;
    }

    .ag-header-gdt-summary-table-right {
        justify-content: flex-end;
    }

}