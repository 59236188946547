@import '../../../Main.module.scss';

.futures {
    &_main_container {
        width: 100%;
        display: flex;
        align-items: flex-start;
    }

    &_calculator_container {
        margin-top: -40px
    }

    &_dropdown_currency, &_dropdown_unit_of_measure {
        margin-left: 10px;
    }
}

.file_selector {
    &_exchanges_button,
    &_exchanges_button_selected {
        @include hover-cursor-pointer;
        @include justify-and-align-flex-items-center;
        color: white;
        display: flex;
        background: $menu-file-selector-theme;
        border: none;
        border-radius: 3px 3px 0 0;
        display: flex;
        flex-direction: row;
        padding: 8px 12px;
        margin-right: 8px;
        height: 34px;
        min-width: 54px;
    }

    &_exchanges_button {
        background-color: $stonex-submenu-gray-blue;
        color: $primary-text-color;
    }

    &_exchange_butttons {
        display: flex;
        flex-flow: row;
        justify-content: space-around;
        align-items: center;
        padding: 9px 0px 9px 12px;
        background-color: $menu-file-selector-theme;
        color: $plainWhite;
        margin-bottom: 20px;
        border-radius: 0px 3px 3px 3px;
    }

    &_empty {
        display: flex;
        height: 30px;
    }

    &_commodity_button {
        font-weight: 700;
        font-size: 16px;
        margin-left: 5px;
        border-radius: 40px;
        flex-direction: row;
        align-items: center;
        padding: 3px 16px;
        position: static;
        height: 30px;
        background: transparent;
        box-sizing: border-box;
        flex: none;
        order: 1;
        flex-grow: 0;
        border: 1px solid transparent;
        color:  $plainWhite;
        @include hover-cursor-pointer;
        &:hover {
            border: 1px solid $plainWhite;
            background-color: rgba(224, 238, 248, 0.3);
        }
    }

    &_commodity_button_selected {
        color: $primary-text-color;
        background: $plainWhite;
        flex-grow: 0;
        &:hover {
            border: 1px solid $plainWhite;
            background-color: $plainWhite;
        }
    }

    &_focus_area {
        flex: 1;
        display: flex;
        flex-flow: row wrap;
    }
    
    &_manage_modal {
        @include hover-cursor-pointer;
        margin: 0px 20px 5px 10px;
        border: none;
        background-color: transparent;
        color: $plainWhite;
        font-size: 14px;
        font-weight: 700;
    }
}