@import '../../../Main.module.scss';

.balance_table {
    &_main_container {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: flex-start;
        gap: 20px;
    }

    &_table_container {
        margin-top: 30px;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 30px;
    }

    &_table {
        height: 100%;
        width: 100%;
    }

    &_chart_container {
        display: flex;
        width: 100%;
        min-width: 100%;
        height: 450px;
        max-height: 450px;
        flex-direction: column;
        align-items: center;
        flex-grow: 1;

        & div div:nth-of-type(2) div div {
            height: 450px;
            max-height: 450px;
        }
    }

    &_first_column {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &_cell_label {
        color: $stonex-blue-theme;
        font-weight: 700;
    }

    &_cell_icon {
        height: 24px;
        width: 24px;
    }
}