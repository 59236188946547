@import "../../../Main.module.scss";

.physical_prices {
    &_main_container {
        width: 100%;
        display: flex;
        align-items: flex-start;
    }

    &_table_container {
        width: 100%;
        max-width: 100%;
    }

    &_limit_message {
        position: absolute;
        margin-top: -25px;
        color: $stonex-medium-gray;
    }

    &_chart_container {
        display: flex;
        width: 100%;
        min-width: 100%;
        height: 650px;
        margin-top: 30px;
        align-items: center;
        flex-grow: 1;
    }

    &_header_wrapper,
    &_selection_wrapper {
        position: relative;
    }

    &_header_dropdown_wrapper {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        flex-basis: 50%;
        grid-gap: 10px;
        gap: 10px;
    }

    &_frequency_icons_container {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: 5px;
        height: 100%;
    }

}