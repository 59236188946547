@import "../../../../Main.module.scss";

.seasonal_table {

    &_container {
        @include justify-and-align-flex-items-center;
        display: flex;
        flex-direction: column;
        height: 365px;
        width: 100%;
        margin-top: 20px;
    }
    
    &_subheader {
        color: $stonex-medium-gray;
        margin-top: -10px;
        margin-bottom: 0;
    }

    &_legend_container {
        @include justify-and-align-flex-items-center;
        display: flex;
        flex-direction: row;
        gap: 12px;
        margin-top: 7px
    }

    &_legend_container p {
        margin-top: 0px;
        margin-bottom: 0px;
    }

    &_forecast_container {
        @include justify-and-align-flex-items-center;
        display: flex;
        flex-direction: row;
        gap: 4px;
    }
    &_year_over_year_label{
        padding-right: 20px;
    }

    &_forecast_square {
        border: 1px solid $stonex-primary-blue;
        padding: 4px 7px 4px 7px;
    }

    &_wrapper_container {
        margin-top: 30px;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 30px;
    }

    &_title_container {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }  

    &_button_container {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
    }

    &_dropdown_image {
        height: 24px;
        width: 24px;
    }

    &_add_and_remove_years {
        display: flex;
        margin: 0 10px;
    }
    
    &_quarterly_header {
        position: relative;
        left: 10px;
        width: 400px;
        text-align: left;
        margin-left: auto;
    } 

    &_yearly_header {
        margin-right: 18px;
    }
    
    &_monthly_header {
        padding-left: 18px;
    }

    &_quarterly_and_yearly_text {
        margin-bottom: 1px;
        color: $primary-text-color;
    }

    &_cell {
        text-align: right;
        color: $primary-text-color; 

        &_year {
            color: $primary-text-color;
            font-weight: 700;
        }

        &_forecast_value {
            border: 1px solid $stonex-primary-blue !important;
        }
    }
}
