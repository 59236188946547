@import '../../../../Main.module.scss';

.sortable_list {
    position: relative;

    &_item {
        cursor: pointer;
        background-color: transparent;
        color: $primary-text-color;
        padding: 5px;

        &::selection {
            outline: none;
            color: $primary-text-color;
            background-color: transparent;
        }

        &_selected {
            cursor: pointer;
            color: $primary-text-color;
            padding: 5px;
            background-color: $ag-row-hover;
            text-decoration-style: none;

            &::selection {
                outline: none;
                color: $primary-text-color;
                background: transparent;
            }
        }
    }

    &_items {
        overflow: auto;
    }

    &_actions {
        display: flex;
        flex-direction: column;
        position: absolute;
        right: -50px;
        top: 0;
        gap: 8px;
    }
}
