@import '../../../../Main.module.scss';

.users {
    &_status_field {
        bottom: 9px;
        margin: 9px 5%;
        line-height: 22px;
        height: 22px;
        width: 90%;
        text-align: center;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 11px;
        border-radius: 3px;
    }

    &_container {
        width: 100%;
    }

    &_table_container {
        width: 100%;
        max-width: 100%;
    }

    &_actions_row {
        display: flex;
        width: 100%;
        margin-bottom: 20px;
        justify-content: space-between;
        flex-direction: row;

        &_input {
            width: 320px;
            height: 40px;
            padding-left: 35px;
            border: 1px solid $stonex-light-gray-blue;
            border-radius: 3px;
            margin: 0px;
        }

        &_details {
            justify-content: end;
        }
    }

    &_companies {
        &_details_container {
            display: flex;
            margin: 20px 0;
        }

        &_detail {
            margin-right: 40px;
        }

        &_detail_label,
        &_detail_value {
            font-size: 14px;
            line-height: 17.57px;
        }

        &_detail_label {
            font-weight: 700;
        }
    }

    &_actions_search {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;
    }

    &_buttons_row {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        gap: 20px;
    }

    &_buttons_row_details {
        margin-bottom: 20px;
    }

    &_heading {
        font-weight: 700;
        font-size: 21px;
        line-height: 40px;
        margin: 20px 0;
        color: $primary-text-color;
    }

    &_items_per_page {
        padding: 0 0 0 12px;
    }

    &_dropdown_container {
        min-width: 90px;
        margin: 0px;
    }

    &_per_page_arrow {
        font-size: 18px;
    }

    &_pages_count {
        padding: 0 20px;
    }

    &_deactivate_button,
    &_import_button,
    &_export_button,
    &_add_user_button {
        padding: 11px 16px;
        text-transform: uppercase;
        border-radius: 3px;
        margin: 0 16px;
        font-size: 14px;
        font-weight: 700;
        letter-spacing: 0.5px;

        &:hover {
            cursor: pointer;
            &:disabled {
                cursor: default;
            }
        }
        &:disabled {
            border: 1px solid $stonex-light-gray-blue;
            background: $deactivated-block-color;
            color: $stonex-disabled-light-gray;
        }
    }

    &_deactivate_link {
        border: none;
        background-color: transparent;
        cursor: pointer;
        padding: 0px;
    }

    &_color_white {
        color: $plainWhite;
        &:hover {
            color: $plainWhite;
        }
    }

    &_add_user_button {
        color: $plainWhite;
        margin-right: 0;
        white-space: nowrap;
        background: $stonex-blue-theme;
        border: 1px solid $stonex-blue-theme;
    }

    &_import_button,
    &_deactivate_button,
    &_export_button {
        background: $plainWhite;
        border: 1px solid $stonex-light-gray-blue;
        color: $primary-text-color;
    }

    &_export_button_image {
        width: 16px;
        height: 16px;
        margin-right: 10px;
        position: relative;
        bottom: 2px;
    }

    &_search_area_icon {
        position: absolute;
        z-index: 1;
        font-size: 12px;
        margin-left: 10px;
        margin-top: 7px;
        color: $stonex-medium-gray;
    }

    &_filter_role {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        width: 100%;
    }

    &_filter_role_dropdown {
        width: 165px;
        border: 1px solid $stonex-light-gray-blue;
        padding: 8px 0px 0px 15px;
        border-radius: 3px;
        margin: 0px;
        float: right;
    }

    &_deactivate_link {
        border: none;
        background-color: transparent;
        cursor: pointer;
        padding: 0px;
        color: $stonex-blue-theme;
    }

    &_actions_container {
        color: $stonex-light-gray-blue;
    }

    &_actions_link_button {
        color: $stonex-blue-theme !important;
        line-height: 16px;
    }

    &_licenses_link_button {
        border: none;
        background-color: transparent;
        padding: initial;
        &:hover {
            cursor: pointer;
        }
    }
}

.users_view {
    &_row {
        width: 100%;
        display: flex;
    }

    &_key_column {
        width: 25%;
        font-family: 'Mulish';
        font-size: 14px;
        font-weight: 700;
        line-height: 40px;
        text-align: left;
    }

    &_value_column {
        font-family: 'Mulish';
        font-style: normal;
        font-weight: 600;
        font-size: 14px;
        line-height: 40px;
    }
}

.import_page {
    padding: 0 20px;
    width: 100%;

    &_container {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-direction: column;
        width: calc(100% - 20px);
        height: 100%;
        padding: 20px;
        gap: 30px;
    }

    &_gridContainer {
        padding: 0;
        height: max-content;
        overflow: auto;
    }

    &_header_container {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    &_importBodyContainer {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
    }

    &_statusCellRenderStyle {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        height: 100%;
        gap: 4px;

        p {
            margin-bottom: 0px;
            margin-top: 0px;
        }
    }
    &_download_import_template_message {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        gap: 3px;

        p {
            margin-top: 0px;
            margin-bottom: 0px;
        }

        a p {
            color: $stonex-blue-theme;
        }
    }
}

.add_edit_user {
    &_page_container {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        width: 100%;
        padding: 20px;
        gap: 30px;
    }

    &_header_container {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: 20px;

        h2 {
            margin-top: 0;
            margin-bottom: 0;
            font-weight: bold;
        }
    }

    &_submit_action_row {
        width: 451px;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 20px;
    }

    &_input {
        width: 451px;
    }

    &_radio_label {
        padding-left: 5px;
    }
}

@media (max-width: 480px) {
    .users {
        margin-top: 70px;
        &_actions_row {
            display: flex;
            width: 100%;
            margin: 20px 0;
            flex-direction: column;
        }
        &_actions_row_buttons {
            margin-top: 20px;
            align-self: flex-end;
        }
    }
}
@media (min-width: 480px) and (max-width: 768px) {
    .users {
        margin-top: 70px;
    }
}
