@import '../../../Main.module.scss';

.faqs {
    &_h3 {
        font-weight: bold;
        font-size: 28px;
        margin-bottom: -5px;
    }

    &_question {
        padding: 20px 0px 3px;
        font-weight: bold;
        font-size: 15px;
    }
}
