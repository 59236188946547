@import '../../../Main.module.scss';

.carousel {
    position: relative;

    &_buttons {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &_button {
        @include hover-cursor-pointer;
        padding: 0;
        width: 50px;
        border: none;
        background-color: transparent;
    }

    &_button img {
        width: 50px;
    }

    &_position_dots {
        padding: 16px 8px 0;
    }
}
