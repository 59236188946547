@import '../../../../Main.module.scss';

$parent-border: #F9F9F9;
$full-width: 100%;
$author-color: #333333;
$linebreak-color: #CFD3D3;

.latest_news {
    &_parent_container {
        flex: 1;
        background: $parent-border;
        border: 1px solid #E7E9E9;
        border-radius: 3px;
        max-height: 510px;
        position: relative;
        z-index: 0;

    }

    &_title_cover {
        padding: 20px 0px 22px 24px;
        position: absolute;
        width: 95%;
        z-index: 10;
        top: 0px;
        background: linear-gradient(360deg, rgba(249, 249, 249, 0) 0%, $parent-border 34.79%);

        h2 {
            font-weight: 700;
            font-size: 15px;
            line-height: 23px;
            width: $full-width;
            margin-bottom: 4px;
    
        }
    }

    &_body {
        display: flex;
        flex-direction: column;
        gap: 13px;
        position: relative;
        overflow-y: auto;
        height: 88%;
        margin-top: 45px;
        padding: 0px 47px 0px 24px;
    
        &_bottom_spacer {
            min-height: 5px;
            width: $full-width;
        }
    
        &_top_scroll {
            position: fixed;
            top: 0px;
            height: 10px;
            background: linear-gradient(360deg, rgba(249, 249, 249, 0) 0%, $parent-border 34.79%);
        }
    
        &_gradient {
            position: sticky;
            z-index: 10;
            bottom: 0px;
            height: 25px;
            width: 100%;
            background: linear-gradient(180deg, rgba(249, 249, 249, 0) 0%, $parent-border 74.79%);
            border-bottom: 1px solid #E7E9E9
        }
    
        h3 {
            font-weight: 700;
            font-size: 13px;
            line-height: 20px;
            color: $stonex-action-blue;
            width: $full-width;
            margin-bottom: 0px;
        }
    
        h4 {
            margin-bottom: .5px;
            font-weight: 600;
            font-size: 12px;
            line-height: 15px;
            color: $author-color;
        }
    
        button {
            cursor: pointer;
            display: flex;
            justify-content: center;
            align-items: flex-start;
        }
    
        &_row {
            all: unset;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            width: $full-width;
            gap: 5px;
            
            &_bottom_row {
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: flex-end;
                width: $full-width;
                gap: 5px;
            }
    
            h5 {
                font-style: normal;
                font-weight: 400;
                font-size: 10px;
                color: #77787A;
                margin-bottom: 0px;
            }
        }
    
        &_line_break {
            width: $full-width;
            border: 1px solid $linebreak-color;
        }
    }

    &_modal {
        max-height: 70%;
        background-color: white;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 65%;
        min-width: calc(80% - 200px);
        border-radius: 3px;
        overflow: auto;

        &_header_container {
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            flex-direction: row;
            padding: 15px;
        }

        &_title {
            &_container {
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                flex-direction: column;
            }

            &_bottom {
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                flex-direction: row;
                gap: 10px;
            }
        }

        &_close_icon_container {
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
            height: 100%;
        }

        h3, h4, h5 {
            font-family: 'Mulish';
        }

        h3 {
            width: $full-width;
            margin: 0px;
            font-size: 18px;
            font-weight: 700;
        }

        h4 {
            margin: 0px;
            font-weight: 600;
            font-size: 14px;
        }

        h5 {
            margin: 0px;
            font-weight: 400;
            font-size: 14px;
            color: #77787A;
        }

        &_header_image {
            width: $full-width;
            object-fit: cover;
            height: 190px;
        }
        
        p {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
        }

        &_body_container {
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            padding: 15px;
            width: $full-width;
            margin-bottom: 10px;
            margin-top: 15px;
        }

        &_article_link {
            @include hover-cursor-pointer;
            background-color: transparent;
            border: none;
            display: flex;
            color: $stonex-blue-theme;
            gap: 1px;
        }

        &_article_link_container {
            width: $full-width;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            padding: 15px;
        }

        a {
            display: flex;
            justify-content: center;
            align-items: flex-end;
        }

        &_bottom_row {
            padding: 15px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-top: 1px solid $linebreak-color;
        }
    }
}


