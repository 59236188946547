@import './Config.module.scss';

body {
    font-family: $application-font-family;
}

.master {
    &_horizontal_scroll {
        overflow-x: scroll;
    }

    &_horizontal_scroll {
        overflow-x: scroll;
    }

    &_subheader_1 {
        @include stonex-bold-font;
        font-size: 18px;
        line-height: 45px;
    }

    &_spacing_between_charts {
        margin-bottom: 32px;
    }

    &_container {
        margin: 0 5%;
        padding: 30px;
    }

    &_gray_container {
        background-color: rgb(230, 230, 230);
        padding: 10px;
    }

    &_flex {
        display: flex;
        flex-wrap: wrap;
    }

    &_vertical_bar {
        color: gray;
        padding: 0 5px;
    }

    &_flex_right {
        justify-content: flex-end;
    }

    &_width_100 {
        width: 100%;
    }

    &_width_50 {
        width: 50%;
    }

    &_stonex_btn {
        padding: 5px 12px;
        border: none;
        font-weight: 700;
        margin: 5px;
        border-radius: 5px;
        color: white;
        background-color: rgb(74, 221, 189);
    }

    &_blue_sidebar {
        background-color: rgb(6, 6, 63);
        color: white;
    }

    &_flex_columns {
        display: flex;
    }

    &_page_container {
        width: 100%;
        padding: 32px;
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    &_future_page_container {
        overflow: hidden;
    }

    &_ag_grid_show_more_button {
        position: relative;
        height: 42px;
        text-align: center;
        padding-right: 12px;
        padding-top: 6px;
        width: 100%;
        border: none;
        border-bottom: 1px solid $stonex-light-gray-blue;
        background-color: $stonex-off-white;
    }

    &_one_third {
        flex-basis: 33%;
        padding-left: 50px;
    }

    &_two_thirds {
        flex-basis: 66%;
    }

    &_tableSource_container {
        @include justify-and-align-flex-items-center;
        margin-top: 10px;
        width: 100%;
        display: flex;
    }

    &_flex_and_float_right {
        display: flex;
        float: right;
    }

    &_spacing_between_charts {
        margin-bottom: 32px;
    }

    &_container {
        margin: 0 5%;
        padding: 30px;
    }

    &_gray_container {
        background-color: rgb(230, 230, 230);
        padding: 10px;
    }

    &_flex {
        display: flex;
        flex-wrap: wrap;
    }

    &_vertical_bar {
        color: gray;
        padding: 0 5px;
    }

    &_flex_right {
        justify-content: flex-end;
    }

    &_width_100 {
        width: 100%;
    }

    &_width_50 {
        width: 50%;
    }

    &_stonex_btn {
        padding: 5px 12px;
        border: none;
        font-weight: 700;
        margin: 5px;
        border-radius: 5px;
        color: white;
        background-color: rgb(74, 221, 189);
    }

    &_blue_sidebar {
        background-color: rgb(6, 6, 63);
        color: white;
    }

    &_flex_columns {
        display: flex;
    }

    &_future_page_container {
        overflow: hidden;
    }

    &_ag_grid_show_more_button {
        position: relative;
        height: 42px;
        text-align: center;
        padding-right: 12px;
        padding-top: 6px;
        width: 100%;
        border: none;
        border-bottom: 1px solid $stonex-light-gray-blue;
        background-color: $stonex-off-white;
    }

    &_one_third {
        flex-basis: 33%;
        padding-left: 50px;
    }

    &_two_thirds {
        flex-basis: 66%;
    }

    &_tableSource_container {
        @include justify-and-align-flex-items-center;
        margin-top: 10px;
        width: 100%;
        display: flex;
    }

    &_flex_and_float_right {
        display: flex;
        float: right;
    }

    &_positive {
        color: $stonex-positive-green !important;
    }

    &_negative {
        color: $stonex-negative-red !important;
    }

    &_neutral {
        color: $black !important;
    }

    &_right_align {
        text-align: right;
    }

    &_left_align {
        text-align: left;
    }

    &_bold {
        @include stonex-bold-font;
    }

    &_table_row {
        cursor: pointer;
        text-align: right;
        color: green;

        &_light {
            @extend .master_table_row;
            background: $plainWhite;
        }

        &_dark {
            @extend .master_table_row;
            background: $table-row-dark;
        }
    }
}

[class~='ag-header-icon'] {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 6px;
}

[class~='ag-header-cell-text'] {
    margin-top: auto;
    margin-bottom: auto;
}

// @media only screen and (max-width: 769px) {
//   html {
//     font-size: 6px;
//   }
// }

// @media only screen and (max-width: 400px) {
//   html {
//     font-size: 6px;
//   }
// }

@media (max-width: 480px) {
    .length_of_time_selected,
    .length_of_time_button {
        font-size: 14px;
    }

    .master {
        &_page_container {
            width: 100%;
            margin: 10px;
            margin-top: 90px;
        }
    }
}

@media (min-width: 480px) and (max-width: 768px) {
    .master {
        &_page_container {
            margin: 10px;
            width: 100%;
            margin-top: 90px;
        }
    }
}
