@import '../../../../Main.module.scss';

.action_modal {
    
    &_container {
        position: relative; 
        width: fit-content; 
        min-width: 500px;
        max-width: 900px;
        background-color: $plainWhite;
        padding: 24px;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-height: calc(100vh - 110px);
        overflow-y: hidden;
    }

    &_modal_header_text {
        @include stonex-bold-font;
        font-size: 18px;
        line-height: 22.5px;
    }

    &_modal_header {
        display: flex;
        justify-content: space-between;
        padding-bottom: 16px;
    }

    &_cancel_set {
        width: 100%;
        padding: 45px 0px 0px 0px;
    }

    &_cancel_set button {
        float: right;
        margin: 0 0 0 20px;
    }

    &_alert_modal_close {
        @include hover-cursor-pointer;
        color: $stonex-disabled-light-gray;
    }

}