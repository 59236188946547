@import '../../../Main.module.scss';

.error_fallback {
    display: flex;
    height: 100%;
    min-height: 100px;
    justify-content: center;
    align-items: center;

    & svg {
        margin-right: 5px;
    }

    &_message {
        @include stonex-bold-font;
        font-size: 18px;
        display: grid;
    }
}