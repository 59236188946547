@import '../../../../Main.module.scss';

.markets_management {
    &_page_container {
        min-width: 1000px;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    &_page_header_section {
        display: flex;
        justify-content: space-between;
    }

    &_active,
    &_inactive {
        margin: 9px;
        height: 22px;
        text-align: center;
        border-radius: 3px;
        text-transform: uppercase;
        font-size: 11px;
        font-weight: 600;
        padding-top: 2px;
    }

    &_active {
        background-color: $active-block-color;
    }

    &_inactive {
        background-color: $deactivated-block-color;
    }

    // Common table stylings
    &_common_table {
        @include common_table_borders;
        gap: 0;

        &_header_large,
        &_header_row div {
            @include common_table_header_text_spacing;
            @include common_table_header_display;
        }

        &_header_row div,
        &_cell {
            width: 15%;
        }

        &_header_row &_header_large,
        &_cell_large,
        &_actions_container {
            width: 20%;
        }

        &_row {
            display: flex;
            border-bottom: 1px solid $stonex-light-gray-blue;
        }

        &_link {
            color: $stonex-blue-theme;
        }

        &_cell,
        &_cell_large,
        &_actions_container {
            margin: 0;
            height: 40px;
            padding: 0px 18px;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        &_actions_container,
        &_header_large,
        &_cell_large {
            min-width: 270px;
        }

        &_actions_container {
            display: flex;
            align-items: center;
        }

        &_cell,
        &_cell_large {
            display: inline-grid;
            align-items: center;

            &_centered {
                @extend .markets_management_common_table_cell;
                justify-items: center;
            }
        }

        &_footer {
            display: flex;
            gap: 10px;
        }
    }
}
