@import '../../../../Main.module.scss';

.licenses_page {
    &_container {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
    
    &_table{
        height: 100%;
        width: 100%;
    }

    &_loading_container {
        height: 144px;
    }

    &_row_container {
        height: 48px;
        display: flex;
        flex: 1;
    }

    &_cell_container {
        height: 100%;
        width: 100%;
        max-width: 300px;
        display: flex;
        border-bottom: 1px solid $stonex-gray-blue-border;
        border-right: 1px solid $stonex-gray-blue-border;
    }

    &_header_cell {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        max-width: 300px;
        background-color: $neutral-color;
        font-size: 14px;
        font-weight: 700;
        line-height: 17.57px;
        border-top: 1px solid $stonex-gray-blue-border;
        border-bottom: 1px solid $stonex-gray-blue-border;
        border-right: 1px solid $stonex-gray-blue-border;
    }
    
    &_totals_name_cell {
        height: 100%;
        width: 100%;
        max-width: 300px;
        font-size: 14px;
        padding-right: 10px;
        padding-left: 15px;
        font-weight: 700;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        border-bottom: 1px solid $stonex-gray-blue-border;
        border-right: 1px solid $stonex-gray-blue-border;
    }

    &_column_sum_cell {
        height: 100%;
        width: 100%;
        max-width: 300px;
        font-size: 14px;
        font-weight: 700;
        line-height: 17.57px;
        font-weight: 700;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $deactivated-block-color;
        border-bottom: 1px solid $stonex-gray-blue-border;
        border-right: 1px solid $stonex-gray-blue-border;
    }

    &_admin_asterisk {
        font-size: 14px;
        font-weight: 400;
        line-height: 40px;
    }

    &_username_cell {
        height: 100%;
        width: 100%;
        max-width: 300px;
        font-size: 14px;
        padding-right: 10px;
        padding-left: 15px;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        border-bottom: 1px solid $stonex-gray-blue-border;
        border-right: 1px solid $stonex-gray-blue-border;
    }

    &_username_text {
        margin-right: 5px;
        font-weight: 600;
        max-width: 150px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    &_active_cell {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $stonex-active-license;
    }

    &_inactive_cell {
        height: 100%;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &_button_wrapper {
        height: 100%;
        width: 100%;
        border: none;
        background-color: transparent;
    }

    &_icon_check {
        color: $stonex-positive-green;
    }
}

@media (max-width: 480px) { 
    .license_management {
        &_username_text{
            max-width: 50px;
        }
  }
} 
@media (min-width: 480px) and (max-width: 768px) {
    .license_management {
        &_username_text{
            max-width: 100px;
        }
    }
}
