@import '../../../Main.module.scss';

.stocks {
    &_main_container {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 20px;
        height: 650px;
        margin-bottom: 30px;
    }

    &_projection_chart, &_seasonal_chart {
        width: 66%;
        height: 100%;
        display: flex;
        flex-direction: column;
    }

    &_commentary_container {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        height: 100%;
        width: 34%;
    }
}
