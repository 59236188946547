@import '../../../../../Main.module.scss';

.market_indicator_seasonal_chart {
    &_footer {
        margin: auto;

        &_item {
            font-size: 12px;
        }

        &_item:not(:last-child) {
            margin-right: 30px;
        }
    }
}
