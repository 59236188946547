@import '../../../../Main.module.scss';

.table_wrapper {
    &_table_container {
        width: 100%;
        max-width: 100%;
    }

    &_pagination_control_box {
        background-color: $stonex-off-white;
        height: 51px;
        display: flex;
        justify-content: right;
        align-items: center;
    }

    &_pagination_control_box_text {
        font-weight: 400;
        color: $primary-text-color;
        font-size: 14px;
        line-height: 18px;
        float: right;
        display: flex;
    }

    &_transparent_button {
        background-color: transparent;
        border: none;

        &:hover {
            cursor: pointer;
        }
    }

    &_pagination_control,
    &_per_page_arrow {
        font-size: 18px;
    }

    &_pages_count {
        padding: 0 20px;
        align-content: center;
    }

    &_select_items_count {
        display: flex;
    }

    &_select_items_text {
        align-content: center;
    }

    &_dropdown div
    {
        box-shadow: none;
        font-size: 14px;
        font-weight: 400;
        background-color: transparent;
        border: none;
    }
}
