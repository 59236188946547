@import '../../../Main.module.scss';

.presentation_templates {
    &_container {
        width: 100%;
        height: 100%;
        margin-bottom: 30px;
    }

    &_description {
        margin-bottom: 30px;
    }

    &_actions {
        &_row {
            margin-bottom: 30px;
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        &_search_container {
            position: relative;
        }

        &_search_input {
            width: 320px;
            height: 40px;
            padding-left: 35px;
            border: 1px solid $stonex-light-gray-blue;
            border-radius: 3px;
            margin: 0px;
        }

        &_search_icon {
            position: absolute;
            z-index: 1;
            font-size: 12px;
            margin-left: 10px;
            margin-top: 7px;
            color: $stonex-medium-gray;
        }
    }

    &_table {
        width: 100%;
        max-width: 100%;
    }

    &_add {
        &_toolbar {
            display: flex;
            background: $stonex-off-white;
            border: 1px solid $stonex-gray-red-hue;
            border-radius: 3px;
            gap: 20px;
            padding: 20px;
            margin-bottom: 30px;
            align-items: end;

            &_section {
                height: 100%;

                &_separator {
                    width: 1px;
                    height: auto;
                    align-self: stretch;
                    background: $stonex-gray-red-hue;
                }
            }

            &_format_options {
                display: flex;
                align-items: flex-start;
                justify-content: flex-start;
                gap: 20px;
            }
        }

        &_selection {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            gap: 32px;

            &_tree {
                width: 400px;
                height: 450px;
                border: 1px solid $stonex-gray-red-hue;
                border-radius: 3px;
                overflow: auto;
            }

            &_order {
                width: 400px;
                border: 1px solid $stonex-gray-red-hue;
                border-radius: 3px;
            }

            &_preview {
                width: 400px;
                height: 50px;
            }
        }
    }

    &_page_element {
        &_image {
            margin-bottom: 15px;

            img {
                max-width: 100%;
                height: 225px;
            }

            &_empty {
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
                height: 225px;
                background: $stonex-light-gray-blue;
            }
        }
    }
}
