@import '../../../Main.module.scss';

.markets_management {
    &_indicators,
    &_indicators_reverse {
        width: 55%;
        min-width: 650px;

        & button {
            font-size: 14px;
        }

        & button img {
            margin-right: 5px;
            margin-top: 3px;
        }
    }

    &_indicators_reverse {
        order: 2;
        margin-left: 25px;
    }

    &_indicator_menu {
        display: flex;
        flex-wrap: wrap;
        background-color: $menu-file-selector-theme;
        padding: 5px;
        border-radius: 3px;
        position: relative;
        bottom: 20px;

        &_button,
        &_button_selected {
            @include hover-cursor-pointer;
            font-size: 16px;
            line-height: 20px;
            font-weight: 700;
            color: $plainWhite;
            font-size: 16px;
            margin: 5px;
            margin-left: 5px;
            border-radius: 40px;
            flex-direction: row;
            align-items: center;
            padding: 3px;
            position: static;
            height: 30px;
            background: transparent;
            box-sizing: border-box;
            flex: none;
            order: 1;
            flex-grow: 0;
            border: 1px solid transparent;
        }

        &_button_selected {
            background-color: $plainWhite;
            color: $primary-text-color;
        }

        &_tabs {
            display: flex;
        }

        &_tab,
        &_tab_selected {
            @include hover-cursor-pointer;
            align-items: center;
            justify-content: center;
            border: none;
            border-radius: 3px 3px 0 0;
            display: flex;
            flex-direction: row;
            padding: 8px 12px;
            margin-right: 8px;
            height: 34px;
            min-width: 54px;
        }

        &_tab {
            background: $stonex-submenu-gray-blue;
        }

        &_tab_selected {
            background: $menu-file-selector-theme;
            color: white;
        }
    }

    &_indicator_row {
        display: flex;
        width: 100%;
    }

    &_add_factor_group {
        @include hover-cursor-pointer;
        display: flex;
        background-color: transparent;
        border: none;
        & img {
            height: 16px;
        }
    }

    &_guage,
    &_guage_reverse {
        min-height: 500px;
        min-width: 500px;
        min-width: 350px;
    }

    &_guage_reverse {
        order: 1;
        display: contents;
        margin-right: 5px;
    }

    &_timeline_chart {
        height: 450px;
    }
}

.indicators_group {
    display: flex;
    padding-bottom: 32px;

    &_display_name {
        font-weight: 400;
        padding-bottom: 16px;
        display: flex;
        line-height: 20px;
        text-transform: uppercase;
        font-size: 16px;
        align-items: center;
        gap: 10px;

        img {
            padding: 5px;
            height: 20px;
            &:hover {
                cursor: grab;
            }
        }

        svg {
            @include hover-cursor-pointer;
            height: 16px;
        }
    }
}

.indicator {
    &_administration,
    &_user {
        width: 144px;
        height: 83px;
        margin-right: 16px;
        border-radius: 6px;
        box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.2);
        position: relative;
        padding: 0;
        border: none;
        background-color: $plainWhite;
    }

    &_user {
        @include hover-cursor-pointer;
    }

    &_user svg,
    &_administration svg {
        @include hover-cursor-pointer;
        height: 16px;
    }

    &_title {
        @include stonex-bold-font;
        text-align: center;
        align-items: center;
        font-size: 12px;
        display: flex;
        justify-content: center;
        height: 40px;
        margin: 0 16px 0;
    }

    &_user svg,
    &_administration svg {
        position: absolute;
        right: 0;
        top: 0;
    }

    &_outlook {
        @include hover-cursor-pointer;
        @include stonex-bold-font;
        text-align: center;
        line-height: 20px;
        font-size: 16px;
        padding: 12px;
        bottom: 0;
        border-radius: 0 0 6px 6px;
        width: 100%;
        border: none;
    }

    &_image {
        width: 80vw;
        height: 80vh;
    }
}

.guage {
    display: flex;
    justify-content: space-between;
    width: 100%;
    position: relative;
    bottom: 50px;
    font-size: 14px;

    &_small {
        display: flex;
        justify-content: space-between;
        width: 100%;
        position: relative;
        bottom: 50px;
        font-size: 12px;
    }

    &_header_small {
        font-size: 18px !important;
    }

    &_card_small {
        border-radius: 4px;
        box-shadow: 1px 1px 4px 1px $stonex-boxshadow-black;
    }

    &_card div,
    &_card_small div {
        height: max-content !important;
    }

    &_card {
        min-height: 500px;
        min-width: 500px;
    }

    &_card_small {
        min-width: 270px;
        background-color: $plainWhite;
        cursor: pointer;
    }

    &_branding_title {
        font-size: 14px;
    }

    &_title,
    &_title_small {
        text-align: center;

        & div {
            align-items: center;
        }
    }

    &_title div h3 {
        font-size: 24px;
    }

    &_title_small div h3 {
        font-size: 18px;
    }

    &_data_highlights, &_data_highlights_small {
        width: 100%;
        position: relative;
    }

    &_data_highlights {
        bottom: 50px;
    }

    &_table {
        margin-top: 5px;
    }

    &_table_bar,
    &_table_bar_small {
        display: flex;
        justify-content: space-between;
        border-top: 1px solid $stonex-light-gray-secondary;
        margin-top: 5px;
    }

    &_table_bar {
        width: 90%;
        margin-left: 5%;
    }

    &_table_bar_small {
        width: 100%;
    }

    &_heading_wrapper {
        font-size: 18px;
    }

    &_heading_wrapper_small {
        font-size: 12px;
    }

    &_value {
        @include stonex-bold-font;
        font-size: 20px;
    }

    &_value_small {
        @include stonex-bold-font;
        font-size: 18px;
    }

    &_positive {
        color: $stonex-bright-green-text;
    }

    &_negative {
        color: $stonex-bright-red-text;
    }

    &_month_over_month {
        position: relative;
        left: 40px;
    }

    &_month_over_month_small {
        display: flex;
        position: relative;
        left: 10px;
    }
}

.indicator_modal {
    position: relative;
    width: 80%;
    height: 80%;
    background-color: #ffffff;
    box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 5px 8px 0px rgb(0 0 0 / 14%), 0px 1px 14px 0px rgb(0 0 0 / 12%);
    padding: 24px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: calc(100vh - 110px);
    overflow-y: hidden;

    &_image {
        display: flex;
        flex-direction: column;
        width: calc(80vw - 10%);
        height: calc(80vh - 20%);
    }

    &_close {
        text-align: right;
    }

    &_close_icon {
        @include hover-cursor-pointer;
        margin-left: 4px;
        color: black;
        height: 24px;
        width: 24px;
    }
}

.market_indicator_review_form {
    position: fixed;
    right: -450px;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1000;
    display: flex;
    align-items: center;
    transition: right 100ms ease-in-out;

    &.visible {
        right: 0;
    }

    &_header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }

    &_description {
        width: 100%;
        font-size: 12px;
        margin-bottom: 8px;
    }

    &_content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        background-color: white;
        padding: 20px;
        border-radius: 3px;
        border: 1px solid $stonex-snackbar-green;
        box-shadow: 0px 8px 16px $stonex-boxshadow-black;
        width: 450px;

        &_textarea {
            width: 406px;
            height: 150px;
            border-radius: 3px;

            &_error {
                @extend .market_indicator_review_form_content_textarea;
                border: 1px solid $stonex-negative-red;
                background-color: $input-error-background;
            }
        }

        &_last_review {
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 12px;
            width: 100%;
            padding: 8px 12px;
            border: 1px solid $stonex-light-gray-blue;
            margin-top: 10px;

            &_status {
                text-wrap: nowrap;
                margin-right: 5px;
            }

            &_reviewed_by {
                margin-right: 5px;
            }

            &_icon {
                margin-right: 5px;

                &_approved {
                    @extend .market_indicator_review_form_content_last_review_icon;
                    color: $stonex-positive-green;
                }

                &_rejected {
                    @extend .market_indicator_review_form_content_last_review_icon;
                    color: $stonex-negative-red;
                }
            }

            &_status {
                @extend .market_indicator_review_form_content_last_review_status;
                font-weight: 700;
            }

            &_reviewed_at {
                margin-left: auto;
            }
        }
    }

    &_button_group {
        display: flex;
        justify-content: flex-end;
        gap: 10px;
        width: 100%;
        margin-top: 10px;
    }

    &_show_button {
        background-color: $stonex-snackbar-green;
        color: white;
        padding: 10px;
        width: 160px;
        height: 42px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        transform: rotate(-90deg);
        transform-origin: center;
        z-index: 1001;
        border-radius: 4px 4px 0 0;
        margin-right: -60px;
        white-space: nowrap;
        text-align: center;
        font-size: 14px;

        &_icon {
            height: 16px;
            margin-right: 8px;
        }
    }
}
