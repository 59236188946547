@import '../../../../Main.module.scss';

.filter_time_span {
    &_outer {
        display: flex;
        justify-content: space-between;
        align-items: baseline;
    }

    &_selected {
        font-family: 'Mulish';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: $stonex-dark-gray;
        flex: none;
        order: 0;
        flex-grow: 0;
        margin: 0px 10px;
        border: none;
        background-color: $stonex-sky-blue;
        border-radius: 3px;
        padding: 9px;
    }

    &_button {
        font-family: 'Mulish';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: $stonex-dark-gray;
        flex: none;
        order: 0;
        flex-grow: 0;
        margin: 0px 10px;
        background-color: transparent;
        border: none;
        padding: 9px;
    }

    &_button:hover {
        @include hover-cursor-pointer;
    }
}
