@import "../../../../../Main.module.scss";


.margin_carousel {

    &_charts {
        position: relative;
        margin: 32px 0;
        height: 600px;
        max-width: calc(100vw - 264px);
        margin-bottom: 32px;
    }

    &_chart {
        width: 100%;
        height: 600px;
    }
}