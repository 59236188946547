@import '../../../Main.module.scss';
.aggregate_region_selector {
    &_parent {
        margin-top: 5px;
        max-height: 400px;
        width: 215px;
        overflow: auto;
    }

    &_header {
        @include stonex-bold-font;
        font-size: 14px;
        line-height: 18px;
    }

    &_switch_label {
        display: flex;
        align-items: center;
        font-size: 14px;
        line-height: 17px;
        margin-bottom: 0px;
    }

    &_parent_switch_label {
        position: relative;
        margin-left: -7px;
    }

    &_button {
        background: $stonex-blue-theme;
        opacity: 0.36;
    }

    &_parent_header {
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    &_active_dropdown_icon {
        width: 12px;
        height: 8px;
        margin-right: 17px;
        margin-bottom: 19px;
        transform: rotate(-90deg);
    }

    &_dropdown_icon {
        width: 12px;
        height: 8px;
        margin-right: 17px;
        margin-bottom: 19px;   
    }

    &_sub_parent_switch_label {
        @include stonex-bold-font;
        font-size: 16px;
        line-height: 40px;
    }
}
