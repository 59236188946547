@import '../../../Main.module.scss';

.hedge_monitor_pro {
    &_halves {
        display: flex;
    }

    &_summary {
        width: 40%;
        min-width: 400px;
    }

    &_summary_margin {
        margin: 20px;
        margin-left: 0;
    }

    &_summary_subtitle {
        font-size: 18px;
        font-weight: 900;
        line-height: 22px;
    }

    &_summary_outlook {
        font-weight: 900;
        font-size: 18px;
        border-width: 1px 0 1px 0;
        border-style: solid;
        padding: 8px;
        margin: 10px;
        margin-bottom: 0;
    }

    &_summary_outlook_center_text {
        text-transform: uppercase;
        margin-top: 8px;
    }

    &_summary_outlook_wrapper {
        align-self: baseline;
        width: 50%;
    }

    &_summary_outlook_box {
        font-size: 14px;
        font-weight: 700;
        text-align: center;
        border-radius: 3px;
        padding: 8px;
        border: 1px;
        height: 54px;
        margin: 24px;
        line-height: 38px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    &_summary_outlook_text {
        align-items: center;
        overflow: hidden;
        text-overflow: ellipsis;

        & a {
            white-space: nowrap;
            color: $stonex-action-blue;
        }

        & svg {
            font-size: 16px;
            vertical-align: sub;
        }
    }

    &_summary_table {
        border-top: 1px solid $stonex-gray-blue-border;
        border-left: 1px solid $stonex-gray-blue-border;
        background-color: $neutral-color;
        margin: 20px;
        margin-left: 0px;
    }

    &_summary_table_header {
        border-right: 1px solid $stonex-gray-blue-border;
        text-align: center;
        font-size: 14px;
        font-weight: 700;
        height: 40px;
        line-height: 40px;
        width: calc(100%/3);
    }

    &_summary_table_value {
        border-right: 1px solid $stonex-gray-blue-border;
        background-color: white;
        text-align: center;
        height: 40px;
        line-height: 40px;
        width: calc(100%/3);
    }

    &_summary_table_row {
        border-bottom: 1px solid $stonex-gray-blue-border;
        display: flex;
    }

    &_market_chart {
        margin-top: 16px;
        height: 500px;
        width: 60%;
    }
}