@import '../../Main.module.scss';

.indicators {
    &_dashboard_section_container {
        max-width: calc(100vw - 264px);
    }

    &_dashboard_section_dynamic_container {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        margin-bottom: 20px;
    }

    &_dashboard_section_left {
        flex: 0 1 auto;
    }

    &_dashboard_section_right {
        flex: 1 1 auto;
        min-width: 600px;
    }

    &_dashboard_section_left:empty + &_dashboard_section_right {
        flex-basis: 100%;
    }

    &_page_container {
        width: min-content;
        margin: 32px;
        display: flex;
        flex-direction: column;
        gap: 20px;
    }

    &_indicators_area {
        @include hover-cursor-pointer;
        padding: 12px;
        max-width: 80%;
    }

    &_dashboard {
        @include stonex-bold-font;
        line-height: 40px;
        font-size: 28px;
    }

    &Container {
        flex: 1;
        display: flex;
        flex-flow: wrap;
    }

    &_align_notification {
        padding: 21px;
    }

    &_dropdowns {
        &_container {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: row;
            gap: 5px;
        }

        min-width: 50px;
        margin: 0px;
    }

    &_header_container {
        display: flex;
        justify-content: space-between;
    }

    &_header {
        display: flex;
        flex-basis: 50%;
    }

    &_dashboard_section {
        min-height: 270px;
        margin-bottom: 32px;
    }

    &_manage_indicators {
        display: flex;
        margin: 15px 15px 15px 0;
    }
}

.language_dropdown {
    border: 1px solid $stonex-light-gray-blue;
    padding: 8px 0px 0px 15px;
    border-radius: 3px;
    float: right;
    margin-bottom: 20px;
}

.userProfile_dropdown {
    margin-right: 20px;
    border: 1px solid $stonex-light-gray-blue;
    padding: 8px 0px 0px 15px;
    border-radius: 3px;
    float: right;
    margin-bottom: 20px;
}

.dynamic {
    &_charts_style {
        display: flex;
        flex-wrap: wrap;
    }

    &_chart {
        height: 600px;
        width: 100%;

        &_container {
            height: 100%;
            width: 100%;
            display: flex;
            flex-flow: row;
            justify-content: flex-start;
            align-items: flex-start;
            background-color: $plainWhite;
            padding: 20px;
            outline: none;
        }
    }

    &_presetchart_news_container {
        display: flex;
        flex-direction: row;
        gap: 30px;
        width: 100%;
        max-height: 75vh;
        margin-bottom: 40px;
        margin-top: 55px;
    }

    &_news_previews_container {
        display: flex;
        flex-direction: row;
        gap: 35px;
        overflow: hidden;
        width: 100%;
    }

    &_latest_news_cover {
        flex: 1;
    }

    &_modal {
        width: auto;
        height: fit-content;
        margin-left: 5%;
        margin-right: 5%;
        margin-top: auto;
        margin-bottom: auto;
        box-shadow: 0px 16px 32px rgba(0, 0, 0, 0.2);
        border-radius: 3px;
    }
}

.dashboard_chart_close_icon {
    display: flex;
    flex-direction: row-reverse;
    height: 100%;
}

.close_icon {
    cursor: pointer;
}

.chart_style {
    flex: auto;
    width: 100%;
}

.preset_chart_style {
    flex: 1 0.45 45%;
    height: 350px;
    margin-right: 15px;
    margin-bottom: 25px;
}

.gdt {
    &_heading_style {
        @include stonex-bold-font;
        font-size: 26px;
        line-height: 45px;
    }

    &_header {
        font-size: 15px;
        font-weight: 700;
    }

    &_header_container {
        padding: 23px 0px 0px;
    }

    &_table_style {
        width: 100%;
    }

    &_price_table_style {
        flex: 1;

        h3 {
            line-height: normal;
        }

        h4 {
            font-family: 'Mulish';
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 18px;
            display: flex;
            align-items: center;
            color: $stonex-medium-gray;
            padding-bottom: 10px;
        }
    }

    &_table_inner_style {
        margin-top: '14px';
    }
}

@media (max-width: 767.98px) {
    .acknowledgements {
        &_modal {
            width: 350px;
        }
    }
}
