@import '../../../../../Main.module.scss';

.historical_market_indicator {
    &_chart_secondary_legend {
        width: 100px;
        margin: 15px;
    }

    &_chart_right_side {
        display: flex;
        width: 100% !important;
        margin: 10px 0;
    }

    &_chart_right_side_label {
        width: 10px;
        height: 10px;
        margin: 7px;
    }
}
