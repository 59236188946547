@import "../../../../../Main.module.scss";

.indicators_management_button {
    @include hover-cursor-pointer;
    text-align: center;
    box-shadow: 1px 1px 4px 1px rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    border: none;
    margin-left: 15px;
    background-color: $plainWhite;
    width: 84px;
    height: 84px;

    img {    
        padding: 21px; 
    }
}