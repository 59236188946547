@import '../../../../Main.module.scss';

.otc_prices {
    &_table_container {
        width: 100%;
        max-width: 100%;
    }

    &_chart_container {
        display: flex;
        width: 100%;
        min-width: 100%;
        height: 650px;
        margin-top: 30px;
        align-items: center;
        flex-grow: 1;
    }

    &_multiple_header_components_container {
        display: flex;
        justify-content: space-evenly;
        gap: 12px;
    }
}