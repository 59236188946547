@import '../../../../Main.module.scss';

.inline_tab {
    &_main_container {
        display: flex;
    }

    &_button,
    &_button_selected {
        @include hover-cursor-pointer;
        @include justify-and-align-flex-items-center;
        font-size: 14px;
        font-weight: bold;
        background: transparent;
        border-radius: 3px 3px 0 0;
        border: transparent;
        padding: 12px 16px;
        min-width: 54px;
        &:first-child {
            margin-left: 0px;
        }
    }

    &_empty_underline_space {
        flex: 1;
    }

    &_button,
    &_empty_underline_space {
        color: $primary-text-color;
        border-bottom: 1px solid $stonex-light-gray-blue;
    }

    &_button_selected {
        border: 1px solid $stonex-light-gray-blue;
        border-bottom: transparent;
    }
}
