@import '../../../Main.module.scss';

.okta_page_container {
    display: flex;
    flex-direction: row;
    overflow: auto;
}

.okta_page_sidebar {
    position: sticky;
    top: 0;
    max-width: 30%;

    @include media_wrapper_tablet {
        z-index: 1;
    }
}

.okta_page_content {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.okta_page_disclaimer {
    margin-top: auto;
    padding: 10px 32px;
    width: 100%;
}
