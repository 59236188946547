@import '../../../Main.module.scss';

$row_background: $stonex-off-white;
$row_border: $deactivated-block-color;

.price_summary {

    &_sparkline {
        max-height: 50px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: $row_background;
        border-top: 1px solid $row_border;
        border-bottom: 1px solid $row_border;
        padding: 10px;
    }

    &_small_dashboard_container {
        flex: 1;

        h3 {
            line-height: normal;
        }
    }   

    &_dashboard_container {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 25px;

        h2 {
            margin-bottom: 0px;
        }
    }

    &_container {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 25px;
    
        h3 {
            margin-bottom: 0px;
        }
    }
    
    &_header {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &_dropdown {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 15px;
            min-width: 190px;

            p {
                white-space: nowrap;
                margin-bottom: 0;
            }
        }
    }

    &_table {
        display: grid;
        grid-auto-rows: auto;
        row-gap: 7px;

        &_container {
            width: 100%;
        }

        @mixin item($numberOfColumns) {
            p {
                display: flex;
                justify-content: flex-end;
                background: $row_background;
                border-top: 1px solid $row_border;
                border-bottom: 1px solid $row_border;
                padding: 10px;
                font-weight: 600;
                margin-bottom: 0;
                white-space: nowrap;
            }

            p:nth-of-type(#{$numberOfColumns}n-#{$numberOfColumns - 1}) {
                justify-content: flex-start;
                align-items: center;
                border-left: 1px solid $row_border;
                border-top-left-radius: 6px;
                border-bottom-left-radius: 6px;
                font-weight: 800;

            }

            p:nth-of-type(#{$numberOfColumns}n) {
                border-left: 0px solid $row_border;
                border-right: 1px solid $row_border;
                border-top-right-radius: 6px;
                border-bottom-right-radius: 6px;
            }
        }

        &_daily {
            @extend .price_summary_table;
            @include item(9);
            grid-template-columns: 11% 15% 8% 1fr 1fr 1fr 1fr 1fr 1fr;
        }

        &_weekly {
            @extend .price_summary_table;
            @include item(10);
            grid-template-columns: 11% 15% 8% .6fr .6fr .6fr .6fr 1.4fr 1fr 1fr;
        }

        &_historical {
            @extend .price_summary_table;
            @include item(7);
            grid-template-columns: 26% 8% 1fr 1fr 1fr 1fr 1fr;
        }

        &_cheese_indices {
            @extend .price_summary_table;
            @include item(6);
            grid-template-columns: 15% 15% 15% 1fr 1fr 1fr;

            &_regular_font_weight  {
                font-weight: 600;
            }
        }

        &_european_union_quotations_small,
        &_cme_spot_small {
            @extend .price_summary_table;
            @include item(4);
            grid-template-columns: 40% 1fr 1fr 1fr;
        }

        &_global_dairy_trade_small {
            @extend .price_summary_table;
            @include item(3);
            grid-template-columns: 50% 1fr 1fr;
        }

        &_header {
            display: flex;
            justify-content: flex-end;
            align-items: flex-end;
            font-size: 12px;
            font-weight: 600;
            margin-bottom: 5px;
            color:$stonex-medium-gray;
            padding-right: 8px;

            &_title {
                @extend .price_summary_table_header;
                justify-content: flex-start;
                padding-right: 0px;
                white-space: nowrap;
            }

            &_large {
                font-size: 19px;
                line-height: 2;
            }
        }
    }

    &_row {
        display: flex;
        justify-content: flex-start;
        background: $row_background;
        border: 1px solid $row_border;
        width: 100%;

        &_container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 10px;
            width: 100%;
        }

        &_header_item {
            flex: 3;
        }

        &_item {
            flex: 1;            
            margin-bottom: 0;
        }
    }
}
