.loading {
    &_spinner {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: auto;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 50px;
        height: 50px;
    }

    &_container {
        position: relative;
        width: 100%;
        height: 100%;
    }
}