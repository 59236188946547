@import "../../../../../Main.module.scss";

.margin_table {
    &_row {
        display: inline-flex;
        height: 32px;
        align-items: end;
    }

    &_header {
        background-color: $stonex-off-white;
        font-weight: bold;
        text-transform: uppercase;
        height: 32px;
        width: 100%;
        display: flex;        
        align-items: center;
        justify-content: right;
        padding-right: 16px;
        border-top: 1px solid $stonex-light-gray-blue;
    }
    
    &_header_wrapper {
        display: flex;
        align-items: end;
        min-width: 80px;
        width: calc(100% - 268px);
        height: 50px;
    }

    &_cell_weight, &_cell_percent {
        height: 32px;
        min-width: 80px;
        width: 100%;

        display: flex;        
        align-items: center;
        justify-content: right;
        padding-right: 16px;
        border-top: 1px solid $stonex-light-gray-blue;
    }

    &_wrapper {
        position: relative;
        max-width: calc(100vw - 268px);
        min-width: fit-content;
    }

    &_wrapper_legend_key {
        display: flex;
        margin: 8px 0 8px 16px;

        & p {
            margin: 1px;
        }

        
        & p:first-of-type {
           font-weight: 700;
           font-size: 14px;
           margin-right: 6px;
        }

        & p:nth-of-type(2) {
            font-size: 10px;
         }
    }

    &_wrapper_bias_label {
        font-weight: 700;
        font-size: 14px;
        justify-content: flex-end;
    }

    &_wrapper_header_right_side {
        display: flex;
        width: 100%;
        justify-content: end;
        align-items: center;
        
        & > div {
            justify-content: flex-end;
            margin-right: 16px;
        }

        & > div:last-of-type {
            margin: 0;
        }
    }

    &_wrapper_header {
        display: flex;
        margin-top: 70px;
        margin-bottom: 32px;

        & > div {
            align-self: center;
            margin: 0;
            min-width: max-content;
        }
        & > div:first-of-type {
            margin-right: 15px;
        }

        & > div:nth-of-type(2) > div:nth-of-type(2) {
            max-width: 140px;

            & input {
                text-align: right;
            }
        }

        & div input {
            width: 140px;
        }

    }

    &_wrapper_loading_spinner {
        height: 400px;
    }
    
    &_wrapper_gradient_div {
        height: 7px;
        width: 33px;
        margin-right: 9px;
        background-image: linear-gradient(to right, $margin-color-scale-risk-1, 
            $margin-color-scale-risk-2, $margin-color-scale-risk-3, $margin-color-scale-risk-4, 
            $margin-color-scale-risk-5, $margin-color-scale-risk-6, $margin-color-scale-risk-7, 
            $margin-color-scale-opportunity-1, $margin-color-scale-opportunity-2, 
            $margin-color-scale-opportunity-3, $margin-color-scale-opportunity-4);
    }

    &_wrapper_footer_text {
        padding-top: 32px;
        text-align: center;
        line-height: 18px;
        font-size: 10px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    &_first_weighted_price_line {
        justify-self: flex-start;
        height: 32px;
        position: relative;
        top: 20px;
        margin-bottom: -32px;

        & p {
            font-weight: 700;
            text-align: center;
            position: relative;
            bottom: 32px;
        }
    }

    &_second_weighted_price_line {
        height: 32px;
        position: relative;
        top: 20px;
        right: 1px;

        & p {
            font-weight: 700;
            justify-self: flex-end;
            text-align: center;
            position: relative;
            bottom: 32px;
        }
    }

    &_row {
        border-left: 1px solid $stonex-light-gray-blue;
        border-right: 1px solid $stonex-light-gray-blue;
        min-width: -webkit-fill-available;
    }
    
    &_wrapper_footer_text {
        border-top: 1px solid $stonex-light-gray-blue;
        font-size: 10px !important;
    }

    &_date_pickers {
        display: flex;
        justify-self: right;
    }

    &_date_picker_margin {
        &:first-of-type {
            width: 100%;
        }

        &:nth-of-type(2) {
            margin: 0 10px;
        }
        
        text-align: center;
        align-self: baseline;
    }
}