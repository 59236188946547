@import '../../../Main.module.scss';

.menu_by_market {
    display: flex;
    flex-wrap: wrap;
    background-color: $menu-file-selector-theme;
    padding: 5px;
    border-radius: 3px;
    position: relative;
    bottom: 20px;

    &_button, &_button_selected {    
        @include hover-cursor-pointer;      
        font-size: 16px;
        line-height: 20px;
        font-weight: 700;
        color: $plainWhite;
        font-size: 16px;
        margin: 5px;
        margin-left: 5px;
        border-radius: 40px;
        flex-direction: row;
        align-items: center;
        padding: 3px 16px;
        position: static;
        height: 30px;
        background: transparent;
        box-sizing: border-box;
        flex: none;
        order: 1;
        flex-grow: 0;
        border: 1px solid transparent;
    }

    &_button_selected {
        background-color: $plainWhite;
        color: $primary-text-color;
    }

    &_tabs {
        display: flex;
    }

    &_tab, &_tab_selected {
        @include hover-cursor-pointer;
        align-items: center;
        justify-content: center;
        border: none;
        border-radius: 3px 3px 0 0;
        display: flex;
        flex-direction: row;
        padding: 8px 12px;
        margin-right: 8px;
        height: 34px;
        min-width: 54px;
    }

    &_tab {
        background: $stonex-submenu-gray-blue;
    }

    &_tab_selected {
        background: $menu-file-selector-theme;
        color: white;
    }
        
}


.menu_flip_wrapper {
    &_icons { 
        display: flex;
        justify-content: flex-end;
        float: inline-end;
        color: $stonex-medium-gray;
        & button {
            background: none;
            color: inherit;
            border: none;
            padding: 0;
            font: inherit;
            cursor: pointer;
            outline: inherit;
        }
    }

    &_bottom_row{
        display: flex;
        justify-content: space-between;
        margin-top: 10px;
    }

    &_breadcrumb {
        @include stonex-bold-font;
        font-size: 14px; 
        color: $stonex-medium-gray;
    }

    &_right_side {
        display: flex;
        justify-content: flex-end;
    }
}

.menu_breadcrumb {
    &_breadcrumb {
        @include stonex-bold-font;
        font-size: 14px; 
        color: $stonex-medium-gray;
    }
}

.menu_with_flip {
    &_topMenu {
        display: flex;
        min-height: 34px;
    }

    &_topItem, &_topItem_selected {
        &_divider {
            width: 1px;
            background-color: $stonex-gray-blue-border;
        }
        @include justify-and-align-flex-items-center;
        height: 34px;
        margin-right: 8px;
        border: none;
        border-radius: 3px 3px 0 0;
    }

    &_topItem_selected {
        background-color: $menu-file-selector-theme;
        color: $plainWhite;
    }

    &_topItem {
        background-color: $stonex-submenu-gray-blue;
    }

    &_topItem_button_selected {
        background-color: $menu-file-selector-theme;
    }
    
    &_topItem_divider{
        width: 1px;
        background-color: $stonex-gray-blue-border;
    }

    &_topItem_button {
        @include hover-cursor-pointer;
        @include justify-and-align-flex-items-center;
        font-family: $application-font-family;
        background-color: transparent;
        border: none;
        display: flex;
        padding: 0;
        color: inherit;
        flex-direction: row;
    }

    &_topItem_button_dropdown {
        align-items: center;
        border-radius: 0 3px 0 0;
        padding: 0 4px;
    }

    &_topItem_button_label {
        align-items: center;
        padding-left: 16px;
        padding-right: 8px;
        border-radius: 3px 0 0 0;
    }

    &_topItem_button_simple {
        align-items: center;
        padding: 0 16px;
        border-radius: 3px 3px 0 0;
    }

    &_subMenu {
        min-height: 48px;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        background-color: $menu-file-selector-theme;
        padding: 10px;
    }

    &_subItem, &_subItem_selected {
        display: flex;
        border:none;
        margin-right: 6px;
        height: 30px;
        border: 1px solid transparent;
        border-radius: 30px;
    }

    &_subItem {
        background-color: $menu-file-selector-theme;
        color: $plainWhite;
        &:hover {
            border: 1px solid $stonex-disabled-light-gray;
        }
    }

    &_subItem_selected {
        color: $primary-text-color;
        background-color: $plainWhite;
        &:hover{
            background-color: $plainWhite;
        }
    }

    &_subItem:hover &_subItem_divider {
        background-color: $stonex-disabled-light-gray;
    }

    &_subItem:hover &_subItem_simple_selector {
        background-color: rgba(224, 238, 248, 0.3);
    }

    &_subItem_divider {
        width: 1px;
        background-color: transparent;
    }

    &_subItem_divider_selected {
        width: 1px;
        background-color: $stonex-disabled-light-gray;
    }

    &_subItem_button {
        @include hover-cursor-pointer;
        font-family: $application-font-family;
        border: none;
        display: flex;
        padding: 0;
        flex-direction: row;
        align-items: center;
        background-color: transparent;
        color: inherit;
        &:hover {
            background-color: rgba(224, 238, 248, 0.3);
        }
    }

    &_subItem_button_dropdown {
        padding: 0 4px;
        border-radius: 0 30px 30px 0;
    }

    &_subItem_button_label {
        padding-left: 16px;
        padding-right: 8px;
        border-radius: 30px 0 0 30px;
    }

    &_subItem_button_simple {
        padding-right: 16px;
        border-radius: 30px;
    }
}

.menu_selector_item {

    &_dropdown_header {
        font-family: $application-font-family;
        color: $primary-text-color;
        font-size: 14px;
        padding: 0 16px;
        padding-top: 8px;
        font-weight: 400;
        line-height: 24px;
        text-transform: uppercase;
    }

    &_dropdown_label {
        font-family: $application-font-family;
        color: $primary-text-color;
        font-size: 14px;
        padding: 0 16px;
        font-weight: 400;
        line-height: 24px;
        padding-left: 28px;
    }
}