@import '../../../Main.module.scss';

.page_header {
    &_page_title {
        @include stonex-bold-font;
        line-height: 40px;
        font-size: 28px;
        margin-bottom: 20px;
        align-self: baseline;
    }
    
    &_page_title_tooltip {
        @include stonex-bold-font;
        line-height: 40px;
        font-size: 28px;
        margin-bottom: 0px;
        align-self: baseline;
    }

    &_tooltip {
        width: 20px;
        margin-left: 10px;
        position: relative;
        top: -3px;
    }

    &_tooltip_middle {
        width: 20px;
        vertical-align: middle;
        margin-left: 10px;
    }

    &_title_container {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &_dropdown_container {
        @include justify-and-align-flex-items-center;
        display: flex;
        flex-direction: row;
    }

    &_fileselector_container {
        width: 100%;
    }

    &_container {
        @include justify-and-align-flex-items-center;
        flex-direction: column;
    }
}

.component_header {
    &_container {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin-bottom: 10px;
    }

    &_main {
        @include stonex-bold-font;
        line-height: 33px;
        font-size: 24px;
        margin-bottom: 0;
    }

    &_sub {
        font-size: 15px;
        color: $stonex-medium-gray;
        font-weight: 600;
        margin-bottom: 0;
    }
}

.component_subheader {
    &_main {
        @include stonex-bold-font;
        line-height: 22px;
        font-size: 15px;
        margin: 0;
    }
}

.header_with_back_navigation {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 20px;

    h3 {
        margin-bottom: 0;
    }
}
