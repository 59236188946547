@import "../../RiskPage.module.scss";

.margin_inputs_table {
    display: flex;
    position: relative;

    &_image {
        width: 20px;
        margin-left: 10px;
    }

    &_sticky_column {
        position: relative;
        top: 56px;
    }

    &_data {
        display: grid;
        max-width: calc(100vw - 512px);
        border-right: 1px solid $stonex-light-gray-blue;
        left: 472px;
        overflow-x: scroll;
    }

    &_collapsible_field {
        @include hover-cursor-pointer;
        display: flex;
        align-items: center;
    }

    &_quarter_headers {
        display: flex;
    }

    &_quarter_header {
        background-color: $table-row-dark;
    }


    &_top_level_background {
        background-color: white;
    }

    &_top_level_background div input,  
    &_level_1_background div input, 
    &_level_2_background div input, 
    &_level_3_background div input {
        text-align: right;
        margin: 8px 16px;
    }

    &_level_1_background {
        background-color: $table-row-dark;
    }

    &_level_2_background {
        background-color: $alternative-neutral-color;
    }

    &_level_3_background {
        background-color: $alternative-neutral-color-level-2;
    }
    
    &_double_border {
        border-top: 1px solid $stonex-light-gray-blue;
    }

    &_final_calculations, &_quarter_header {
        min-width: 480px;
        max-width: 480px;
        height: 56px;
        text-align: center;
        border: 1px solid $stonex-light-gray-blue;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &_base_column_text_only,
    &_updated_column_text_only {
        padding-right: 32px !important;
        text-align: right;
        min-width: 160px;
        max-width: 160px;
        height: 56px;
        overflow: hidden;
        border: 1px solid $stonex-light-gray-blue;
        display: inline-flex;
        align-items: center;
        justify-content: flex-end;
    }

    &_data_results {
        display: flex;
    }

    &_group {
        min-width: 480px;
        max-width: 480px;
    }

    &_data_group_bold {
        font-weight: 700;
    }

}

