@import '../../../Main.module.scss';

// Will start putting all of the colors directly in file for registration.
 $plainWhite: white;
 $primary-text-color: #333333;
 $card-border: #040c1726;
 $card-background: #f3f8fe;
 $midnight-blue: #040c17;

.registration_activate {
    &_background {
        background-image: url($oktaUrl); 
        background-repeat: no-repeat;
        background-position: 50%;
        background-size: cover;
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }
    
    &_auth_content {
        margin: 24px;
    }

    
    &_container {
        @include vertical-and-horizontal-center-align;
        font-size: 14px;
        z-index: 1;
        line-height: 18px;
        border-radius: 3px;
        width: 546px;
        background-color: $plainWhite;
    }
    
    &_title {
        font-weight: 700;
        font-size: 18px;
        line-height: 22px;
    }

    &_title, &_password_requirements {
        margin-bottom: 32px;
    }

    &_password_validation {
        // By default there is margin below since this is a paragraph, therefore do NOT change this to margin-left: 5px.
        margin: 0 0 0 5px;
    }

    &_submit_action_row {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        gap: 20px;
    }

    &_input {
        width: 396px;
        margin-bottom: 24px;
    }

    &_validation_image {
        height: 14px;
        margin-right: 5px;
    }
    
    &_input_area, &_welcome_message {
        margin-bottom: 8px;
    }

    &_input {
        width: 100%;
        max-width: 286px;
    }


    @media (max-width: 768px)  {
        &_container {
            max-width: 546px;
            width: 80%;
            margin-left: 10%;
        }
    }
}

.registration_self {

    &_logo_header_container {
        position: absolute;
        z-index: 1;
        background-color: white;
        width: 100%;

        & img {
            width: 212px;
            padding: 5px 5px 5px 48px;
        }
    }

    &_form_input_flex {
        display: flex;

        & div {
            width: 90%;
        }

        & div:nth-child(2) div {
            float: right;
        }
    }

    &_form_input {
        margin: 12px 0;
        & div input, & div div {
            width: 100%;
            max-width: 700px;
        }
    }

    &_form_container {
        background-color: $plainWhite;
        padding: 24px;

        & div {
            color: $primary-text-color;
        }
    }

    &_center {
        text-align: center;
    }

    &_section_header, &_section_header_white {
        font-weight: 700;
        line-height: 45px;
        font-size: 38px;
        margin-bottom: 32px;
    }

    &_page_content_container {
        width: 70%;
        margin:  126px 15% 0;
    }

    &_form_laptop_image {
        max-width: -webkit-fill-available;
    }

    &_form_header_image {
        position: absolute;
         color: white;
        height: 838px;
        width: 100%;
        background-image: url('../../Assets/Registration/stoneXPlusHero.jpg');
        background-position: center center;
        background-repeat: no-repeat;
    }

    &_section_spacer {
        margin-top: 96px;
    }

    &_section_double_spacer {
        margin-top: 196px;
    }

    &_halves_container, &_halves_midnight_container {
        position: relative;
        display: flex;

        & div {
            min-width: 50%;
        }

        & > div:first-of-type {
            margin-right: 15px;
        }

        & > div:nth-of-type(2) {
            margin-left: 15px;
        }
    }

    &_halves_midnight_container {
        margin-bottom: 200px;
    }
    
    &_two_thirds {
        width: 66%;
    }

    &_thirds_container {
        display: flex;
        justify-content: space-between;

        & div {
            width: 32%;
        }
    }

    &_midnight_container {
        background-color: $midnight-blue;
        width: 100%;
         color: white;
    }

    &_quarters_container {
        position: relative;
        display: flex;
        justify-content: space-between;

        & div {
            width: 24%;
        }
    }

    &_quarter_card_title {
        font-size: 30px;
        font-weight: 700;
        line-height: 36px;
    }

    &_quarter_card {
        padding: 24px;
        border-radius: 4px;
        border: 1px solid $card-border;
        gap: 16px;
        background-color: $card-background;
        
    }

    &_card {
        text-align: left;
        width: 100%;
        padding: 24px;
        border-radius: 4px;
        border: 1px solid $card-border;
        gap: 16px;
        background-color: $card-background;
    }

    &_form_indicator_logo {
        max-width: -webkit-fill-available;
    }

    &_request_information_card {
        position: relative;
        bottom: 200px;
    }

    &_request_information_button {
        display: flex;
        align-items: center;

    }

    &_form_container, &_benefits_container {
        width: 50%;
        z-index: 2;
        color: $plainWhite !important;
    }

    &_form_benefit {
        display: flex;

        & img {
            margin: 5px;
            width: 20px;
            height: 20px;
        }
    }

    &_form_benefit_text {
        margin: 5px;
    }

    &_section_white {
        color: white;
    }

    &_section_header_white {
        color: white;
    }

    &_benefits_container {
        width: 100%;
    }

    &_summary_logo {
        width: 283.44px;
    }

    &_summary {
        margin: 50px;
    }

    &_list_checkmark {
        width: 20px;
        margin: 5px 5px 0;
    }

    &_success {
        background-color: $midnight-blue;
        color: $plainWhite;
    }

    &_success_header {
        margin: 0 20%;
        width: 60%;

        ul {
            list-style: none;
            padding: 0px;
        }
    }

    &_success_main_header {
        margin: 100px 0 0;
        font-weight: 700;
        font-size: 48px;
        line-height: 57px;
    }

    &_success_subheader {
        margin-top: 10px;
        font-weight: 400;
        font-size: 20px;
    }

    &_success_what_happens_next {
        margin-top: 10px;
        font-weight: 700;
        line-height: 29px;
        font-size: 24px;
    }

    &_frequently_asked_questions_container {
        margin-top: 47.5px
    }

    &_laptop_image {
        max-width: -webkit-fill-available;
    }
    
    &_twist_down_wrapper {
        width: 100%;
        margin-top: 16px;

        div {
            position: relative;
        }

        div h3 {
            width: 90%;
        }

    }

    &_collapse_expand {
        position: absolute;
        right: 10px;
        top: 26px;
        border: none;
        background-color: transparent; 
        width: 50px;

        & svg {
            width: 50px;
            height: 35px;
            cursor: pointer;
        }
    }

    &_back_to_top {
        text-align: right;
    }

    @media (max-width: 1025px) {

        
        &_quarters_container {
            flex-wrap: wrap;
    
            & div {
                width: 49%;
                margin-bottom: 2%;
            }
        }
    
        &_page_content_container {
            width: 90%;
            margin:  126px 5% 0;
        }
    }

    @media (max-width: 768px) {
        &_halves_container, &_halves_midnight_container, &_thirds_container {
            flex-wrap: wrap;

            & div {
                width: 100%;
                margin-bottom: 2%;
            }
        
            & > div:first-of-type {
                margin-right: unset;
            }
    
            & > div:nth-of-type(2) {
                margin-left: unset;
            }
        }

        &_first_container {
            background-color: $midnight-blue;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 225%;
            margin: 0;
        }

        &_form_header_image { 
            position: static;
            z-index: 2;
            height: 300px;
            width: 90%;
            margin: calc(48px + 5%) 5% 5%;
        }

        &_page_content_container {
            margin-top: 0;
        }

        &_section_spacer {
            margin-top: 43px;
        }
    
        &_section_double_spacer {
            margin-top: 96px;
        }
        &_halves_midnight_container {
            margin-bottom: 100px;
        }

        &_request_information_card {
            position: relative;
            bottom: 100px;
        }

        &_laptop_image {
            width: 100%;
        }

    }

    @media (max-width: 575px) {

        &_two_thirds, &_request_information_button, &_request_information_button button, &_halves_container div button, &_back_to_top button {
            width: 100%;
        }
        
        &_quarters_container {
    
            & div {
                width: 100%;
                margin-bottom: 2%;
            }
        }

        &_form_header_image { 
            height: 200px;
        }
    
    }
}

